module.exports = global.lang_impressum = {
    titles: {
        en: {
            h1: "IMPRESSUM",
            h2: "CONCEPT, DESIGN, REALISATION",
            h3: "PICTURE CREDITS",
            h4: "COPYRIGHTS",
            h5: "NOTE",
            h6: "TERMS OF USE"
        },
        it: {
            h1: "IMPRONTA",
            h2: "CONCETTO, PROGETTAZIONE, REALIZZAZIONE",
            h3: "CREDITI IMMAGINE / COPYRIGHT",
            h4: "DIRITTI D'AUTORE",
            h5: "AVVISO",
            h6: "TERMINI DI UTILIZZO"
        },
        fr: {
            h1: "MENTIONS LÉGALES",
            h2: "CONCEPT, DESIGN, RÉALISATION",
            h3: "CRÉDITS PHOTOS / COPYRIGHTS",
            h4: "DROITS D'AUTEUR",
            h5: "AVIS",
            h6: "CONDITIONS D'UTILISATION"
        },
        ge: {
            h1: "IMPRESSUM",
            h2: "KONZEPT, DESIGN, REALISATION",
            h3: "BILDNACHWEIS / URHEBERRECHTE ",
            h4: "COPYRIGHTS ",
            h5: "HINWEIS ",
            h6: "NUTZUNGSBEDINGUNGEN"
        },
        rr: {
            h1: "",
            h2: "",
            h3: "",
            h4: "",
            h5: ""
        },
    },
    text: {
        en: {
            h1: "Association for the Promotion of Sports Talent in Switzerland\n" +
                "Allmeindstrasse 17\n" +
                "CH-8840 Einsiedeln\n" +
                "\n" +
                "info@eusi-talent.ch\n",
            h2: "Lexigo GmbH, CH-1003 Lausanne",
            h3: "The copyright and all other rights to the content, images, photos or other files on the website belong " +
                "exclusively to the Association for the Promotion of Sports Talent in Switzerland or to the specifically named " +
                "rights holders. The written consent of the copyright holders must be obtained in advance for the reproduction " +
                "of any elements.",
            h4: "© illustrations of works and text: according to photo credits",
            h5: "The information, texts and images contained in the website are intended solely to provide information about " +
                "the Association for the Promotion of Sports Talent in Switzerland. Commercial use and use in publications " +
                "(sound, images and text) are only permitted with the prior written consent of the Association for " +
                "the Promotion of Sports Talent in Switzerland."
        },
        it: {
            h1: "Associazione per la promozione dei talenti sportivi in Svizzera\n" +
                "Allmeindstraße 17\n" +
                "CH-8840 Einsiedeln\n" +
                "\n" +
                "info@eusi-talent.ch\n",
            h2: "Lexigo GmbH, CH-1003 Losanna",
            h3: "I diritti d'autore e tutti gli altri diritti su contenuti, immagini, foto o altri file sul sito Web " +
                "appartengono esclusivamente all'Associazione per la promozione dei talenti sportivi in Svizzera o ai " +
                "titolari dei diritti specificatamente nominati. L'autorizzazione scritta dei titolari dei diritti d'autore " +
                "deve essere ottenuta in anticipo per la riproduzione di qualsiasi elemento.",
            h4: "© Illustrazioni di opere e testi: secondo i crediti fotografici.",
            h5: "Le informazioni, i testi e le immagini contenuti nel sito web sono destinati esclusivamente a fornire " +
                "informazioni sull'associazione per la promozione dei talenti sportivi in Svizzera. Gli scopi commerciali e " +
                "l'uso in pubblicazioni (suono, immagine e testo) sono consentiti solo previo consenso scritto dell'Associazione " +
                "per la promozione dei talenti sportivi in Svizzera."
        },
        fr: {
            h1: "Association pour la promotion des talents sportifs en Suisse\n" +
                "Allmeindstrasse 17\n" +
                "CH-8840 Einsiedeln\n" +
                "\n" +
                "info@eusi-talent.ch\n",
            h2: "Lexigo Sàrl, CH-1003 Lausanne",
            h3: "Le droit d'auteur et tous les autres droits sur le contenu, les images, les photos ou d'autres " +
                "fichiers sur le site Web appartiennent exclusivement à l'Association pour la promotion des talents " +
                "sportifs en Suisse ou aux titulaires de droits spécifiquement nommés. L'autorisation écrite des " +
                "détenteurs des droits d'auteur doit être obtenue au préalable pour la reproduction de tout élément.",
            h4: "© Illustrations de l'oeuvre et du texte : selon crédits photos",
            h5: "Les informations, textes et images contenus sur le site sont exclusivement destinés à fournir des " +
                "informations sur l'association pour la promotion des talents sportifs en Suisse. Les fins commerciales " +
                "et l'utilisation dans des publications (son, image et texte) ne sont autorisées qu'avec l'accord écrit " +
                "préalable de l'Association pour la promotion des talents sportifs en Suisse."
        },
        ge: {
            h1: "Verein zur Förderung von Sporttalenten in der Schweiz\n" +
                "Allmeindstrasse 17\n" +
                "CH-8840 Einsiedeln\n" +
                "\n" +
                "info@eusi-talent.ch\n",
            h2: "Lexigo GmbH, CH-1003 Lausanne",
            h3: "Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf " +
                "der Website gehören ausschliesslich der dem Verein zur Förderung von Sporttalenten in der " +
                "Schweiz oder den speziell genannten Rechtsinhabern. " +
                "Für die Reproduktion jeglicher Elemente ist die schriftliche " +
                "Zustimmung der Urheberrechtsträger im Voraus einzuholen.",
            h4: "© Werk- und Textabbildungen: gemäss Fotonachweis",
            h5: "Die im Webauftritt enthaltenen Informationen, Texte und Bilder, dienen ausschliesslich der " +
                "Information über den Verein zur Förderung von Sporttalenten in der Schweiz. Kommerzielle " +
                "Verwendungszwecke und die Verwendung in Publikationen (Ton, Bild und Text) sind nur mit vorheriger " +
                "schriftlicher Genehmigung vom Verein zur Förderung von Sporttalenten in der Schweiz gestattet."
        },
        rr: {
            h1: "",
            h2: "",
            h3: "",
            h4: "",
            h5: ""
        },
    }
}