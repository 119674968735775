import React, {Component} from 'react'
import lang_donators from '../modules/lang_donators.js'

import background1 from "../../src/assets/images/mountain.jpg";
const slideStyle1={ backgroundImage: `url(${background1})`};

class donators extends React.Component {
    constructor(props) {
        super(props);
        this.donatorlist = [
            'Cane Markus Schönenberg/ZH',
            'Erb Alejandro Einsiedeln',
            'Newson Simon Horgen',
            'Palü Partners AG Zollikon',
            'RaeberLaw Einsiedeln',
            'Surace Guido, Einsiedeln',
            'Talent2Pro GmbH Einsiedeln',
            '',
            ''];
        this.donatorhtml = []
        this.state = {
            donatorsrendered: []
        }
    }

    renderdonators = () => {
        if(this.donatorhtml.length < 1){
            this.donatorlist.forEach((item, index) => {
                this.donatorhtml.push(<div key={index + '_div'} className="col-sm-4 p-3">
                                            <div className="flex-md-row flex-column">
                                                 <div className="heading-5 box-creative-title">{item}</div>
                                            </div>
                                        </div>);
            })
        }
        return this.donatorhtml;
    }

    /*changeState = () => {
        this.setState({
            donatorsrendered: this.donatorhtml
        })
    }*/



    render(){


        return(
                <>
                <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                    <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                    <div className="breadcrumbs-main">

                    </div>
                    <div className="breadcrumbs-panel">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="section section-xl bg-default text-left">
                        <div className="container">
                            <div className="inset-xl-left-10 mb-5">
                                <h3>{lang_donators.headline[this.props.language]}</h3>
                                <p className="big">{lang_donators.story[this.props.language]}</p>
                                <a className="button button-lg button-gradient" href="/bank">
                                    {lang_donators.button[this.props.language]}</a>
                            </div>
                            <div className="inset-xl-left-10">
                                <h3>{lang_donators.subheadline[this.props.language]}</h3>
                            </div>
                            <div className="row row-xl no-gutters ">
                                {this.renderdonators()}
                            </div>
                        </div>
                    </section>

                </>
        );
    }
}

export default donators;