import React, {useState, forwardRef, useImperativeHandle} from 'react';
import custom from '../assets/css/custom.module.css'
import background1 from "../../src/assets/images/swiper-1-slide-3.jpg";
import {useCookies} from "react-cookie";
import {Link, Route, Routes} from "react-router-dom";
import language_about from "../modules/lang_about.js";

const slideStyle1={ backgroundImage: `url(${background1})`};
const image_dir = "https://www.eusi.ch/images/";


const about = forwardRef((props, ref) => {
    const [cookies, setCookie, removeCookie] = useCookies(["language"]);
    const [language, setLanguage] = useState(props.language);
    const hlang = language_about;


    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark " style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">

                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2>{hlang.title[language]}</h2>
                </div>
            </section>
            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="video">
                                <img className="video-image" src="https://www.eusi.ch/images/thumbnail2.png" alt="" width="549" height="384"/>
                                <a className="video-link" href={hlang.videolink[language]} data-lightgallery="item">
                                    <span className="icon mdi mdi-play"></span>
                                </a>
                            </article>
                        </div>

                        <div className="col-md-6">
                            <div className="inset-xl-left-70">

                                <p className="big">{hlang.mission[language]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2>Impressum</h2>
                    <Link className={custom.mybutton} to="/impressum">{hlang.impressum[language]}</Link>
                </div>

            </section>

        </>
    );
});

export default about;