import React, {useState} from 'react'
import {Link} from "react-router-dom";
import custom from '../assets/css/custom.module.css'

function VideoList(props){
    let videos = props.videos
    let rows = []
    console.log(props)

    const [likes, setLikes] = useState(100);
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = (event) =>{
        if (isClicked) {
            setLikes(likes - 1);
        } else {
            setLikes(likes + 1);
        }
        setIsClicked(!isClicked);
    }

    const stylediv = {
        clear:'left'
    }

    const stylespan = {
        fontSize: '13px',
        lineHeight: '0.5 !Important'
    }

    const stylelikes = {
        width:'20px',
        border:'none'
    }
    const likesbtn = {
        border:'none',
        backgroundColor:'inherit',
        cursor:'pointer'
    }

    const videoListMe = () =>{
        for (let i = 0; i < videos.length; i++) {
            let youtube = "https://www.youtube.com/embed/" + videos[i]['youtube'];
            rows.push(<div className="col-sm-6 col-lg-4" key={i} >
                            <article className="box-minimal">
                                <div className="unit align-items-center">
                                    <div className="unit-left">
                                        <img src="https://www.eusi.ch/images/icons/wintersport_1.png"  width="30" height="30" />
                                    </div>
                                    <div className="unit-body">
                                        <div className="heading-4 box-minimal-title">{videos[i]['title_' + props.language]}</div>
                                    </div>
                                    <div className="unit-boody block-left">
                                        <div className="heading-4">
                                            {/* <iframe className="mt-2" src={youtube} width="300" allow="autoplay; encrypted-media" allowFullScreen title="video"/> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={stylediv}>
                                    <iframe className="mt-4" frameBorder="0" src={youtube} width="300" allow="autoplay; encrypted-media" allowFullScreen title="video"/>
                                </div>
                                <div style={stylediv}>
                                    <p style={stylespan}>{videos[i]['desc_' + props.language]}</p>
                                    <p style={stylespan}>Mentor: {videos[i]['mentor']}</p>
                                </div>
                                <div className="mt-2 mb-3">
                                    <button style={likesbtn} onClick={ handleClick }>
                                        <div className="float-left"><img style={stylelikes} src="https://www.eusi.ch/images/likes.png"/></div>
                                        <div className="float-left pt-1 ml-2"><span className="likes-counter">{likes }</span></div>
                                    </button>
                                </div>
                            </article>
                      </div>)




            {/*
            rows.push(
                        <div key={i} className={custom.bc + " col-sm-4 col-lg-4 align-content-md-start ml-5 text-left pt-2 pr-2 pl-2" }>
                            <span><b>{videos[i]['title_en']}</b></span><br/>
                            <iframe className="mt-2" src={youtube} width="300" allow="autoplay; encrypted-media" allowFullScreen title="video"/>
                            <div className="mb-0">
                                <span className={custom.st} >{videos[i]['desc_en']}</span><br/>
                                <span className={custom.st}>Mentor: {videos[i]['mentor']}</span>
                            </div>
                            <div className="mt-2 mb-3">
                                <button className={custom.likesbtn} onClick={ handleClick }>
                                    <div className="float-left"><img className={custom.likes} src="https://www.eusi.ch/images/likes.png"/></div>
                                    <div className="float-left pt-1 ml-2"><span className="likes-counter">{likes }</span></div>
                                </button>
                            </div>
                        </div>
                        );
            */}
        }
    }

    videoListMe()


    return (
        <>
            {rows}
        </>
    );

}

export default VideoList