module.exports = global.lang_sponsoring = {
    headline:{
        ge:'Sponsoring',
        fr:'Sponsoring',
        it:'Sponsoring',
        en:'Sponsoring'
    },
    story:{
        ge: {
            part1:'Eusi Talent bietet Sponsoren eine erstklassige Plattform, sich dem Marktsegment von jungen Sportlern und Sportlerinnen schweizweit und '+
                   'in allen Landessprachen zu präsentieren. Sponsoren können so ihre Produkte und Marken mit der Swissness und Positivität des Eusi Talent Konzeptes assoziieren ',
            part2:'(DOWNLOAD BRANDING PDF ‘EUSI presentation’).',
            part3:'Sponsoren haben die Wahl zwischen verschiedenen ' +
                'Möglichkeiten/Paketen. Natürlich sind wir auch bereit, mit jeweiligen Sponsoren ein massgeschneidertes Paket zu erarbeiten.\n\n' +
                'Eusi Talent bietet Sponsoren im Grundsatz zwei Varianten an:',
            part4:'a) Plattform Sponsoren – Gute Präsenz mit Logo auf der gesamten Eusi Talent Plattform: LINK',
            part5:'b) Content Sponsoren – Präsenz mit Logo, Product Placement, allenfalls Mitwirkung in einzelnen Videos, oder einer Video-Serie\n\n' +
                'Sponsoren können sich einbringen durch Geldspenden, Sachspenden, und/oder Gratisdienstleistungen für Eusi Talent.\nDie Hauptkosten von Eusi Talent sind:\n\n' +
                'a) Produktion von Videos \n' +
                'b) Auf- und Ausbau der Webseite\n' +
                'c) Spesenentschädigungen für Mentoren\n' +
                'd) Werbematerialien und Präsenz an Sportanlässen\n' +
                'e) Generelle Gemeinkosten Verein (Buchhaltung, Büromaterial, etc.)\n\n' +
                'Eusi Talent gewährt qualifizierten Sponsoren unter Beachtung der Datenschutz- sowie der Sponsoringvereinbarung Zugang zur Datenanalyse der Webseite zwecks Auswertung.',
        },
        fr:{
            part1: 'Nos Talents offre aux sponsors une plateforme de premier ordre pour se présenter au segment de marché des jeunes sportifs et sportives dans toute la Suisse et dans toutes les langues nationales. ' +
            'Les sponsors peuvent ainsi associer leurs produits et leurs marques à la Swissness et à la positivité du concept Nos Talents.',
            part2:'(DOWNLOAD BRANDING PDF ‘EUSI presentation’).',
            part3:'Les sponsors ont le choix entre différentes possibilités/paquets. Bien entendu, nous sommes également prêts à élaborer un paquet sur mesure avec les sponsors concernés.\n\n' +
            'Nos Talents propose en principe deux variantes aux sponsors :\n',
            part4:'a) Sponsors de Plateform – Bonne présence avec logo sur l\'ensemble de la plateforme Nos Talents (for more Details check link)',
            part5:'b) Sponsors de Contenu – Présence avec logo, placement de produit, éventuellement participation à des vidéos individuelles ou à une série de vidéos (für mehr Details: LINK)\n\n' +
            'Les sponsors peuvent contribuer par des dons financiers, des dons en nature, et/ou des services gratuits pour Nos Talents.\n' +
            'Les principaux coûts de Nos Talents sont:\n\n' +
            'a) la production de vidéos\n' +
            'b) la mise en place et le développement du site web\n' +
            'c) Indemnités de frais pour les mentors\n' +
            'd) Matériel publicitaire et présence lors d\'événements sportifs\n' +
            'e) Frais généraux de l\'association (comptabilité, matériel de bureau, etc.)\n' +
            'Nos Talents accorde aux sponsors qualifiés l\'accès à l\'analyse des données du site Internet à des fins d\'évaluation, dans le respect de la protection des données et de la convention de sponsoring.\n'},
        it:{
            part1: 'Nostri Talenti offre agli sponsor una piattaforma di prim\'ordine per presentarsi al segmento di mercato dei giovani sportivi in tutta la Svizzera e in tutte le lingue nazionali. ' +
                'Gli sponsor possono così associare i loro prodotti e marchi alla svizzerità e alla positività del concetto di Eusi Talent.',
            part2: '(DOWNLOAD BRANDING PDF \'EUSI presentation\')',
            part3:'Gli sponsor possono scegliere tra diverse opzioni/pacchetti. Naturalmente, siamo pronti a trovare una soluzione su misura per ogni sponsor.\n\n' +
            'Nostri Talenti offre agli sponsor due opzioni di base:\n',
            part4:'Platform Sponsor – (LINK) -will be provided later\n',
            part5:'Content Sponsor –  (LINK) – will be provided later\n Gli sponsor possono contribuire con denaro o donazioni in natura e/o servizi gratuiti a Nostri Talenti o ai suoi membri. Le spese principali di Eusi Talent sono:\n\n' +
            'a) costi di produzione dei video\n' +
            'b) sviluppo ed espansione del sito web\n' +
            'c) rimborso spese per i mentori\n' +
            'd) materiale promozionale e presenza ad eventi sportivi\n' +
            'e) spese generali di associazione (contabilità, forniture per ufficio, ecc.).\n' +
            'Nostri Talenti concede agli sponsor qualificati l\'accesso all\'analisi dei dati del sito web a scopo di valutazione, nel rispetto della protezione dei dati e dell\'accordo di sponsorizzazione.\n'},
        en: {part1:'Eusi Talent offers sponsors a first-class platform to present themselves to the market segment of young sportsmen and sportswomen throughout ' +
                'Switzerland and in all national languages. Sponsors can thus associate their products and brands with the Swissness and positivity of the Eusi Talent concept',
            part2: '(DOWNLOAD BRANDING PDF \'EUSI presentation\') -- sent separately\n',
            part3: 'Sponsors have the choice between different options/packages. Of course, we are also ready to find a bespoke solution with any sponsor.\n\n' +
            'Eusi Talent offers sponsors two basic options:\n',
            part4:'a) Platform Sponsors – Good logo presence across the platform Our Talents (for more Details check link)\n',
            part5:'b) Content Sponsors – Presence with logo, product placement, possibly participation in individual videos or a video series\n(for more details: LINK) – will be provided later\n\n'+
            'Sponsors can contribute through cash or, in-kind donations, and/or free services to Eusi Talent, or its members. The main expenditures of Eusi Talent are' +
            'a) Production cost of videos\n' +
            'b) Development and expansion of the website\n' +
            'c) reimbursement of expenses for mentors\n' +
            'd) Promotional materials and presence at sporting events\n' +
            'e) General overhead costs association (accounting, office supplies, etc.)\n\n' +
            'We grant qualified sponsors access to the analysis of website data for evaluation purposes, in compliance with data protection and the sponsorship agreement.\n'}

    },
    footer:{
        ge:'Bei Interesse, freut sich Eusi Talent auf Ihre Kontaktaufnahme per Mail: sponsoring@eusi-talent.ch',
        fr:'Si vous êtes intéressé(e), Nos Talents se réjouit de votre prise de contact par mail: sponsoring@eusi-talent.ch',
        it:'Se siete interessati, Nostri Talenti vi aspetta per e-mail: sponsoring@eusi-talent.ch',
        en:'If you are interested, Eusi Talent is looking forward to hearing from you by mail: sponsoring@eusi-talent.ch'
    }
}

