import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import lang_configsignin from "../modules/lang_configsignin.js";
import {formaudit} from '../modules/formcheck'
import signObjectValidation from '../modules/signObjectValidation.js'
import resetpassObjectValidation from '../modules/resetpassObjectValidation.js'
import {postRest} from '../modules/postRest';
import {patchRest} from '../modules/patchRest.js';
import {useCookies} from "react-cookie";

const signinmodal = forwardRef((props, ref) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["route", "session", "user"]);
    const [error_message, setErrMessage] = useState(props.language);
    const [showSignin, setShowSignin] = useState(true);
    const [language, setLanguage] = useState(props.language);
    const langSignin = lang_configsignin;
    const obj = signObjectValidation;
    const obj2 = resetpassObjectValidation;
    const [buttonVisible, setButtonVisible] = useState(true);

    useImperativeHandle(ref, () => ({
        setSigninLanguage(lang){
            setLanguage(lang);
        }
    }));

    const navigateTo = (path) => {
        navigate(path);
        navigate(0);
    }

    const handleOnSigninChange = (event) =>{
        //event.isDefaultPrevented();
        //console.log(event);
        obj.form[event.target.id].value = event.target.value;
    }

    const signMeIn = (e) => {
        e.preventDefault()
        let checkform = formaudit(obj);
        if(checkform.success){
            postRest(checkform.post,'https://www.eusi.ch/webservice/postsignin').then((r) => {
                if(r.hasOwnProperty('success')){
                    console.log('session', r);
                    if(r.result.hasOwnProperty('loggedin')){
                            setCookie("session", '', { path: '/' });
                            setErrMessage(obj.server_error_message)

                        } else {
                            setCookie("user", r.result, {path: '/'});
                            setCookie("session", r.result.id, {path: '/'});
                            //let changeWord = props.changeWord;
                            //props.onChange();
                            navigate('/videocategory')
                            props.onHide();
                            window.location.href = '/videocategory'
                    }
                }
            })
            setErrMessage(checkform.message)
        } else {
            setErrMessage(checkform.message)
        }
    }

    const resetMyPass = (e) =>{
        e.preventDefault();
        let checkform = formaudit(obj2)
        if(checkform.success){
            console.log("success")
            setErrMessage(checkform.message)
            console.log('params' , checkform.post);
            patchRest(checkform.post, 'https://www.eusi.ch/webservice/requestpasswordreset').then(r =>{
                console.log('r', r)
                if(r.result.hasOwnProperty('email')){
                    setErrMessage(obj2.server_success_message)
                } else {
                    setErrMessage(obj2.server_error_message)
                }
            })
        } else {
            setErrMessage(checkform.message)
        }
        setButtonVisible(false);
    }

    const handleOnResetChange = (event) =>{
        obj2.form[event.target.id].value = event.target.value;
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <div className=" col-md-12 col-xl-12">
                    <button type="button" className="close" aria-label="Close" onClick={props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {showSignin && <h4>{langSignin.signin.headline[language]}</h4>}
                    {!showSignin && <h4>{langSignin.resetpass.headline[language]}</h4>}

                </div>
            </Modal.Header>
            <Modal.Body>
                <div className=" col-md-12 col-xl-12">
                    <p className="title-2">eusi.ch</p>
                    {showSignin &&
                    <form className="rd-form rd-mailform rd-form-centered">
                        <div className="form-wrap">
                            <input className="form-input" id="reg_email" onChange={handleOnSigninChange} type="text" name="email" placeholder={langSignin.signin.email[language]}  />
                        </div>
                        <div className="form-wrap">
                            <input className="form-input" id="reg_pass" onChange={handleOnSigninChange} type="password" name="email" placeholder={langSignin.signin.password[language]} />
                        </div>
                        <div>
                            <p className="error">{error_message[language]}</p>
                        </div>
                        <span>&nbsp;</span>
                        <div className="form-button align-content-md-end">
                            <button className="button button-lg button-gradient" onClick={signMeIn} to={'/#'} type="submit">{langSignin.signin.button[language]}</button>
                        </div>
                    </form> }
                    {!showSignin &&
                        <form className="rd-form rd-mailform rd-form-centered">
                            <div className="form-wrap">
                                <input className="form-input" id="reg_email2" type="text" onChange={handleOnResetChange} name="email" placeholder={obj2.form.reg_email2.placeholder[language] } />
                            </div>
                            <div>
                                <p className="error">{error_message[language]}</p>
                            </div>
                            <span>&nbsp;</span>
                            <div className="form-button align-content-md-end">
                                {buttonVisible && (
                                    <button className="button button-gray-100 " type="submit" onClick={resetMyPass}>{obj2.form.button.name[language] }</button>
                                    )}
                            </div>
                        </form> }
                </div>
            </Modal.Body>
            <div className="flex-column align-content-end m-4 ">
                <Button className="button-xs d-inline-block m-1" to={'#'}  size={'sm'} li={'/register'} onClick={()=>navigateTo('/register')} >{langSignin.newuser[language] }</Button>
                <Button  className="button-xs d-inline-block m-1" to={'#'}  size={'sm'} onClick={()=>setShowSignin((!showSignin))} >{(showSignin) ? langSignin.forgotpass[language] : langSignin.backtosingin[language]}</Button>
                {/*<Button  className="button-xs d-inline-block m-1" size={'sm'} onClick={props.onHide}>{langSignin.close[language]}</Button> */}
            </div>
        </Modal>
    );
});

export default signinmodal

