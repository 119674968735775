import React, {useState, forwardRef, useImperativeHandle} from 'react';
import custom from '../assets/css/custom.module.css'
import background1 from "../../src/assets/images/swiper-1-slide-2.jpg";
import {useCookies} from "react-cookie";
import language_team from '../modules/lang_team';
import {Link, Route, Routes} from "react-router-dom";
import language_home from "../modules/lang_home";
const slideStyle1={ backgroundImage: `url(${background1})`};
const image_dir = "https://www.eusi.ch/images/";


const about = forwardRef((props, ref) => {
    const [cookies, setCookie, removeCookie] = useCookies(["language"]);
    const [language, setLanguage] = useState(props.language);
    const hlang = language_team;

    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2>Unser Team</h2>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="team block-md"><img className="team-image"
                                                                    src="https://www.eusi.ch/images/team1.png" alt="" width="320"
                                                                    height="320"/>
                                <div className="team-content">
                                    <div>
                                        <div className="team-position">Präsident</div>
                                        <div className="heading-5 team-name"><a href="#">Dennis</a></div>
                                        <ul className="team-list-social list-social list-inline list-inline-sm">
                                            <li><a className="icon mdi mdi-linkedin" href="https://www.linkedin.com/in/dennis-räber-9ba5741a0/?originalSubdomain=ch"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>



                        <div className="col-md-6">
                            <div className="inset-xl-left-70">
                                <h2>Dennis Räber</h2>
                                <article className="quote-minimal">
                                    <div className="heading-4 quote-minimal-text">
                                        <p className="q">{hlang.title1[language]}</p>
                                    </div>
                                </article>
                                <p className="big">{hlang.text1[language]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="team block-md"><img className="team-image"
                                                                    src="https://www.eusi.ch/images/team2.jpg" alt="" width="320"
                                                                    height="320"/>
                                <div className="team-content">
                                    <div>
                                        <div className="team-position">{hlang.title2[language]}</div>
                                        <div className="heading-5 team-name"><a href="#">Guido</a></div>
                                        <ul className="team-list-social list-social list-inline list-inline-sm">
                                            <li><a className="icon mdi mdi-web" href="https://www.egmond-consulting.com"></a></li>
                                            <li><a className="icon mdi mdi-linkedin" href="https://www.linkedin.com/in/surace/"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="col-md-6">
                            <div className="inset-xl-left-70">
                                <h2>Guido Surace</h2>
                                <article className="quote-minimal">
                                    <div className="heading-4 quote-minimal-text">
                                        <p className="q">{hlang.title2[language]}</p>
                                    </div>
                                </article>
                                <p className="big">{hlang.text2[language]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="team block-md"><img className="team-image"
                                                                    src="https://www.eusi.ch/images/team3.jpg" alt="" width="320"
                                                                    height="320"/>
                                <div className="team-content">
                                    <div>
                                        <div className="team-position">{hlang.title3[language]}</div>
                                        <div className="heading-5 team-name"><a href="#">Annina</a></div>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="col-md-6">
                            <div className="inset-xl-left-70">
                                <h2>Annina Räber</h2>
                                <article className="quote-minimal">
                                    <div className="heading-4 quote-minimal-text">
                                        <p className="q">{hlang.title3[language]}</p>
                                    </div>
                                </article>
                                <p className="big">{hlang.text3[language]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="team block-md"><img className="team-image"
                                                                    src="https://www.eusi.ch/images/team4.jpg" alt="" width="320"
                                                                    height="320"/>
                                <div className="team-content">
                                    <div>
                                        <div className="team-position">{hlang.title4[language]}</div>
                                        <div className="heading-5 team-name"><a href="#">Nicolas</a></div>
                                        <ul className="team-list-social list-social list-inline list-inline-sm">
                                            <li><a className="icon mdi mdi-web" href="https://www.nicolas-ackermann.ch"></a></li>
                                            <li><a className="icon mdi mdi-instagram" href="https://www.instagram.com/acki.ski/"></a></li>
                                            <li><a className="icon mdi mdi-linkedin" href="https://www.linkedin.com/in/nicolas-ackermann-46414a207/"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>

                        <div className="col-md-6">
                            <div className="inset-xl-left-70">
                                <h2>Nicolas Ackermann</h2>
                                <article className="quote-minimal">
                                    <div className="heading-4 quote-minimal-text">
                                        <p className="q">{hlang.title4[language]}</p>
                                    </div>
                                </article>
                                <p className="big">{hlang.text4[language]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
});

export default about;