import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {getRest} from '../modules/getRest.js'
import {useCookies} from "react-cookie";
import VIDEOLIST from './videoList.js'
import {Link, useParams} from "react-router-dom";
import background1 from "../assets/images/swiper-1-slide-1.jpg";
const slideStyle1={ backgroundImage: `url(${background1})`};

function Videos(props, ref) {
    const [cookies, setCookie, removeCookie] = useCookies(["language", "route", "user", "itemList", "videoList", "dataloaded", "category"]);
    const [language, setLanguage] = useState((props.language) ? props.language : 'en');
    const [server_error, setServerError] = useState('');
    let url = useParams();
    const [videoList, setVideoList] = useState({});



    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));



    if(cookies.category !== url.url || videoList.length === undefined) {
        console.log('is this fireing')
        getRest('getvideosincategory/' + cookies.user.id + "/" + url.url).then(r => {
            console.log(r)
            setCookie('category', url.url, {path:'/'});
            setVideoList(r.result);
        });
    }

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">&nbsp;</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>Videos</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2 className="title-7 wow fadeInUp">Video category {url.url.charAt(0).toUpperCase() + url.url.slice(1)}</h2>
                </div>
                <div className="section-md bg-default section-decor section-decor-left">
                    <div className="container">
                        <div className="row row-xl no-gutters bordered-2 ordered-1 wow fadeInRight">
                            { (videoList.length>0) && <VIDEOLIST videos={videoList} language={language} /> }
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}

export default forwardRef(Videos)