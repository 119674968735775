module.exports = global.lang_resetpass = {
    headline:{
        en:'Lets set a new password',
        fr:'Définissons un nouveau mot de passe',
        ge:'Legen wir ein neues Passwort fest'
    },
    subheadline:{
        en:'Dedicated to inspiering excellence',
        fr:'Dédié à l`excellence inspirante',
        ge:'Der inspirierenden Exzellenz verschrieben'
    },
    ingress:{
        en:'Create a new password in the form below',
        fr:'Créez un nouveau mot de passe dans le formulaire ci-dessous',
        ge:'Erstellen Sie im folgenden Formular ein neues Passwort'
    },


};