import React from "react";
import lang_banking from "../modules/lang_banking";
import background1 from "../../src/assets/images/ski_community.jpg";
import language_config from "../modules/lang_config";
const slideStyle1={ backgroundImage: `url(${background1})`};

class banking extends React.Component {

    constructor(props){
        super(props);
        this.custom_text_style = {
            whiteSpace: 'pre-line'
        }
        this.custom_img_style = {
            width: '150px'
        }
    }

    render() {
        return (
            <>
                <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                    <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                    <div className="breadcrumbs-main">
                        <div className="container">
                        </div>
                    </div>
                    <div className="breadcrumbs-panel">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="section section-xl bg-default text-left">
                    <div className="container">
                        <div className="inset-xl-left-10 mb-5">
                            <h3>{lang_banking.headline[this.props.language]}</h3>
                            <p className="big" style={this.custom_text_style}>{lang_banking.bank.oganisation[this.props.language]}</p>
                            <p className="big">
                                {lang_banking.bank.headlinebankaddress[this.props.language]}<br/>
                                {lang_banking.bank.bankname}<br/>
                                {lang_banking.bank.address} <br/>
                                {lang_banking.bank.country}  <br/>
                                {lang_banking.bank.iban} <br/>
                                <img style={this.custom_img_style} src='https://www.eusi.ch/images/bankcode.jpg' /><br/><br/>
                                {lang_banking.bank.ref[this.props.language]} <br/><br/>
                                {lang_banking.bank.bankname}<br/>
                                {lang_banking.bank.address} <br/> {lang_banking.bank.country}  <br/>
                                {lang_banking.bank.bic}<br/><br/>
                                {lang_banking.bank.oganisation[this.props.language]}<br/>
                                {lang_banking.bank.acct}
                            </p>
                        </div>
                        <div className="inset-xl-left-10">
                            <h3>&nbsp;</h3>
                        </div>
                        <div className="row row-xl no-gutters ">
                            &nbsp;
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default banking

