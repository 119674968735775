// noinspection LanguageDetectionInspection

import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import language_home from '../modules/lang_home';
import lang_config from '../modules/lang_config.js'
import {useCookies} from "react-cookie";
//eslint-disable-next-line
import background1 from "../../src/assets/images/misson.mp4";
const slideStyle4={ backgroundVideo: `url(${background1})`};
const image_dir = "https://www.eusi.ch/images/";

function Home(props, ref){
    const [cookies, setCookie, removeCookie] = useCookies(["language", "route"]);
    const [language, setLanguage] = useState((props.language) ? props.language : lang_config.languageSelected);
    const hlang = language_home;
    const videosection = language_home.videosection;

    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
           setLanguage(lang);
           console.log('imerpativeHandle Home: ', lang);
       }
    }));

    function soundSwitch(){
        let video = document.querySelector('video');
        let sound = document.getElementById('mutestyle');
        let url = 'https://www.eusi.ch/images/'
        video.muted = !video.muted;
        sound.src = (!video.muted) ? url + 'unmute.png': url + 'mute.png'
    }

    const myVideo = {
        /* 'position': "fixed", */
        'minWidth': "100% !Important",
        'width':'100% !Important',
        'backgroundPosition': 'center center'
    }

    const divStyle2 = {
        'position':'relative',
        'top':'0px',
        'left':'0px',
        'width':'100%',
        'height':'100%',
        'overflow':'hidden',
        'border':'solid 3px',
    }

    const videoStyle = {
        'objectFit': 'cover',
        'width': '100vw',
        'height': '100vh',
        'position': 'fixed',
        'top': 0,
        'left': 0
    }

    const mutestyle = {
        width: '40px',
        height:'40px'
    }

    const soundstyle= {
        backgroundColor: 'transparent',
        border:'none'
    }

    const sound = {
        position:'fixed',
        right:'50px',
        bottom:'50px'
    }

    const imgopacity = {
        opacity:'0.1 !Important'
    }

    const dropshadow = {
        textShadow: '1px 1px #ff0000'
    }

    return (

        <>
            <section className=" section swiper-container swiper-slider swiper-slider-1" style={myVideo} data-autoplay="5000" data-slide-effect="fade" data-simulate-touch="false">
                <div className="swiper-wrapper" style={myVideo}>
                    <div style={divStyle2} id='videoItem'>
                        <video playsInline autoPlay muted loop style={videoStyle} poster="https://www.eusi.ch/images/mountain.jpg" id='videoItem'>
                            <source src={hlang.video[language]} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="swiper-content context-dark pb-xxl-5 align-content-xl-end">
                    <div className="container wow fadeInUp mt-lg-5">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p >https://{hlang.homeurl[language]}</p>
                        <h3 >{hlang.videotext[language]}<br/></h3>
                        <a className="button button-lg button-gradient" href="/register">{hlang.register[language].button}</a>
                    </div>
                    <div style={sound}>
                        <p>
                            <button onClick={soundSwitch} style={soundstyle}>
                                <img src="https://www.eusi.ch/images/mute.png" id="mutestyle" style={mutestyle} />
                            </button>
                        </p>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-md-50 align-items-center">
                        <div className="col-lg-5 col-xl-5 wow fadeInUp">
                            <div className="inset-xl-right-70">
                                <div className="title-10">{hlang.homeurl[language]}</div>
                                <div className="divider">&nbsp;</div>
                                <h3 className="text-transform-none">{hlang.register[language].title}</h3>
                                <p className="big">{hlang.register[language].sub}</p>
                                <a className="button button-lg button-gradient" href="/register">{hlang.register[language].button}</a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-xl-2 wow fadeInUp">&nbsp;</div>
                        <div className="col-lg-5 col-xl-5 wow fadeInUp">
                            <div className="inset-xl-right-70">
                                <div className="title-10">&nbsp;</div>
                                <div className="divider">&nbsp;</div>
                                <h3 className="text-transform-none">{hlang.register[language].alttitle}</h3>
                                <p className="big">{hlang.register[language].altsub}</p>
                                <a className="button button-lg button-gradient" href="/about">{hlang.register[language].altbutton}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section text-center">
                <div className="section-md bg-default section-decor section-decor-left">
                    <div className="container">
                        <div className="heading-2 box-minimal-title">{hlang.videocategories.headline[language]}</div>
                        <div className="row row-xl no-gutters bordered-2 ordered-1 wow fadeInRight">
                            <div className="col-sm-6 col-lg-4">
                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/fotball_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][0]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">

                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/wintersport_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][1]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">

                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/finance_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][2]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/nutrition_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][3]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/education_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][4]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">

                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/jobs_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][5]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">

                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/socialmedia_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][6]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                            <div className="col-sm-6 col-lg-4">

                                <article className="box-minimal">
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src="https://www.eusi.ch/images/icons/pshycology_1.png"  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{hlang.videocategories[language][7]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item"></div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-gray-700 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-xl-6 index-1">
                            <div className="section-lg section-inset-4 inset-xl-right-70 wow fadeInLeft">
                                <h2 className="title-7"><span>{hlang.midsection[language].title}</span></h2>
                                <h4>{hlang.midsection[language].sub}</h4>
                                <p className="big">{hlang.midsection[language].text}</p><a className="button button-lg button-gradient"
                                                           href="single-class.html">{hlang.register[language].button}</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-xl-6 counter-classic-wrap">

                            <div className="counter-classic wow fadeInRight">
                                <div className="unit unit-spacing-2">
                                    <div className="unit-left">
                                        <div className="counter-classic-main">
                                            <div className="heading-1 counter-classic-number counter">45</div>
                                            <div className="heading-3 counter-classic-postfix">k</div>
                                        </div>
                                    </div>
                                    <div className="unit-body">
                                        <div
                                            className="counter-classic-icon mdi mdi-account-multiple-outline"></div>
                                        <div className="big counter-classic-text">{hlang.midsection[language].first}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="counter-classic wow fadeInRight">
                                <div className="unit unit-spacing-2">
                                    <div className="unit-left">
                                        <div className="counter-classic-main">
                                            <div className="heading-1 counter-classic-number counter">78</div>
                                            <div className="heading-3 counter-classic-postfix">%</div>
                                        </div>
                                    </div>
                                    <div className="unit-body">
                                        <div className="counter-classic-icon mdi mdi-account-convert"></div>
                                        <div className="big counter-classic-text">{hlang.midsection[language].second}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="counter-classic wow fadeInRight">
                                <div className="unit unit-spacing-2">
                                    <div className="unit-left">
                                        <div className="counter-classic-main">
                                            <div className="heading-1 counter-classic-number counter">5</div>
                                            <div className="heading-3 counter-classic-postfix">k</div>
                                        </div>
                                    </div>
                                    <div className="unit-body">
                                        <div className="counter-classic-icon mdi mdi-map"></div>
                                        <div className="big counter-classic-text">{hlang.midsection[language].third} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*   <div className="image-classic-wrap">
                        <img className="image-classic image-classic-1 wow fadeIn"
                             src="https://www.eusi.ch/images/ski_community1.jpg"
                        />

                    </div> */}
                </div>
            </section>f

            <section className="parallax-container bg-gray-700 text-center parallax-no-fixed" data-parallax-img="https://www.eusi.ch/images/mountain.jpg">
                <div className="parallax-content section-lg">
                    <div className="container wow fadeInUp">
                        <h3><span>{hlang.parralax.title[language]}</span></h3>
                        <p className="big">{hlang.parralax.text[language]}<br className="d-none d-lg-block"/></p>
                        <a className="button button-lg button-gradient" href="/register">{hlang.parralax.button[language]}</a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default forwardRef(Home);