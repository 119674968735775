module.exports = global.lang_configsignin = {
    signin:{
        headline:{
            en:'Sign in',
            fr:'S`identifier',
            ge:'Anmelden'
        },
        email:{
            en:'Email / Username',
            fr:'E-mail / Nom d`utilisateur',
            ge:'E-Mail / Benutzername'
        },
        password:{
            en:'Password',
            fr:'Mot de passe',
            ge:'Passwort'
        },
        button:{
            en:'Sign in',
            fr:'S`identifier',
            ge:'Anmelden'
        },
        error_message:{
            en:'Hmmmm..... Your username and password does not match',
            fr:'Hmmmm..... Votre nom d\'utilisateur et votre mot de passe ne correspondent pas',
            ge:'Hmmmm..... Ihr Benutzername und Passwort stimmen nicht überein'
        },
        success_message:{
            en:'Welcome back',
            fr:'C`est bon de te revoir',
            ge:'Willkommen zurück'
        }
    },
    resetpass:{
        headline:{
            en:'Reset Password',
            fr:'Réinitialiser le mot de passe',
            ge:'Passwort zurücksetzen'
        },
        email:{
            en:'Email / Username',
            fr:'E-mail / Nom d`utilisateur',
            ge:'E-Mail / Benutzername'
        },
        button:{
            en:'Reset Password',
            fr:'réinitialiser le mot de passe',
            ge:'Passwort zurücksetzen'
        },
        error_message:{
            en:'Hmmmm..... This email does not exist',
            fr:'Hummm..... Cet email n`existe pas',
            ge:'Hmmmm..... Diese E-Mail existiert nicht'
        },
        success_message:{
            en:'You will soon receive an email with further instructions',
            fr:'Vous recevrez bientôt un e-mail avec des instructions supplémentaires',
            ge:'Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen'
        }
    },
    newuser:{
        en:'New User',
        fr:'Nouvel utilisateur',
        ge:'Neuer Benutzer'
    },
    close:{
        en:'Close',
        fr:'Proche',
        ge:'Schließen'
    },
    forgotpass:{
        en:'Forgot password?',
        fr:'Mot de passe oublié?',
        ge:'Passwort vergessen?'
    },
    backtosingin:{
        en:'Back to login',
        fr:'Retour à la connexion',
        ge:'Zurück zur Anmeldung'
    }
};