module.exports = global.lang_banking = {
    headline:{
        ge:'Bank Details',
        fr:'Coordonnées bancaires',
        it:'Coordinate bancarie',
        en:'Bank Details'
    },
    bank:{
        oganisation:{
            ge:'Verein zur Förderung von Sporttalenten in der Schweiz 8840 Einseiedeln',
            fr:'Association pour la Promotion des Talents Sportifs en Suisse 8840 Einsiedeln',
            it:'Associazione per la Promozione dei Talenti Sportivi in Svizzera 8840 Einsiedeln',
            en:'Association for the Promotion of Sports Talents in Switzerland 8840 Einsiedeln'
        },
        headlinebankaddress:{
            ge:'Bankdaten:',
            fr:'Coordonnées bancaires:',
            it:'Coordinate bancarie:',
            en:'Bank details:'
        },
        bankname:'Schwyzer Kantonalbank',
        address:'8840 Einsiedeln',
        country:'Switzerland',
        iban:'IBAN CH62 0077 7009 2393 9789 2',
        ref:{
            ge:'Falls Sie als Gönner oder Gönnerin nicht genannt sein wollen, bitte als Überweisungsreferenz ‘NEIN’ angeben.',
            fr:'Si vous ne souhaitez pas être nommé comme donneur, insérez "NON" comme référence.',
            it:'Se non desiderate essere nominati come donatori, indicate "NO" come riferimento della rimessa.',
            en:'If you do not wish to be named as a donor, please indicate "NO" as the remittance reference.'
        },
        bic:'BIC/Swift: KBSZCH22XXX',
        acct:'Acct. No. 923939-7892'
    },
}

