module.exports = global.newpasswordObjectValidation = {
    form: {
        reg_pass1:{
            'value':'',
            'required':true,
            'type':'password',
            'label':'',
            'placeholder': {
                en:'Set new password',
                fr:'Definir un nouveau mot de passe',
                ge:'Neues Passwort festlegen'
            },
            err_message:{
                en:'Hmmm... Please enter a valid password',
                fr:'Hum... Veuillez entrer un mot de passe valide',
                ge:'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        },
        reg_pass2:{
            'value':'',
            'ref': 'reg_pass1',
            'required':true,
            'type':'passpass',
            'label':'',
            'placeholder': {
                en:'Repeat password',
                fr:'Répéter le mot de passe',
                ge:'Passwort wiederholen'
            },
            err_message:{
                en:'Hmmm... Your passwords dont match',
                fr:'Hum... Vos mots de passe ne correspondent pas',
                ge:'Hmmm... Ihre Passwörter stimmen nicht überein'
            }
        },
        button:{
            name:{
                en:'Save new password',
                fr:'Enregistrer le nouveau mot de passe',
                ge:'Neues Passwort speichern'
            }
        }
    },
    formsuccess_message: {
        en: 'Communicating with server',
        fr: 'Communiquer avec le serveur',
        ge: 'Kommunikation mit dem Server'
    },
    server_error_message: {
        en: 'Unable to find any users with that email',
        fr: 'Impossible de trouver des utilisateurs avec cet e-mail',
        ge: 'Es konnten keine Benutzer mit dieser E-Mail-Adresse gefunden werden'
    },
    server_success_message: {
        en: 'Your password have been updated',
        fr: 'Votre mot de passe a été mis à jour',
        ge: 'Ihr Passwort wurde aktualisiert'
    }
}