export async function getRest( destination){
    const defaulturl = 'https://www.eusi.ch/webservice/'
    let url = defaulturl + destination;
    let options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cookie': document.cookie,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        //body: JSON.stringify(obj)
    };
    let response = await fetch(url, options);
    let responseOK = response && response.ok;
    if (response) {

        let data = await response.json();
        return data;
    }
}