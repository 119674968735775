module.exports = global.regObjectValidation = {
    form:{
        reg_name:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a valid name',
                fr:'Hmmm... Veuillez entrer un nom de valide',
                ge:'Hmmm... Bitte geben Sie einen gültigen namen ein'
            }
        },
        reg_fname:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a valid family name',
                fr:'Hmmm... Veuillez entrer un nom de famille valide',
                ge:'Hmmm... Bitte geben Sie einen gültigen Familiennamen ein'
            }
        },
        reg_email:{
            'value':'',
            'required':true,
            'type':'email',
            err_message:{
                en:'Hmmm... Please enter a valid e-mail',
                fr:'Hummm... Veuillez saisir une adresse e-mail valide',
                ge:'Hmmm... Bitte geben Sie eine gültige E-Mail-Adresse ein'
            }
        },
        reg_phone:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a phone number',
                fr:'Hum... Veuillez entrer un code postal',
                ge:'Hmmm... Bitte geben Sie eine Postleitzahl ein'
            }
        },
        reg_pass:{
            'value':'',
            'required':true,
            'type':'password',
            err_message:{
                en:'Hmmm... Please enter a valid password',
                fr:'Hum... Veuillez entrer un mot de passe valide',
                ge:'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        },
        reg_sport:{
            'value':'',
            'required':true,
            'type':'select',
            err_message:{
                en:'Please select sport',
                fr:'Veuillez définir votre date de naissance',
                ge:'Bitte geben Sie Ihren Geburtstag ein'
            }
        },
        reg_languagepref:{
            'value':'',
            'required':true,
            'type':'select',
            err_message:{
                en:'Please set your birthday',
                fr:'Veuillez définir votre date de naissance',
                ge:'Bitte geben Sie Ihren Geburtstag ein'
            }
        },

        reg_street:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a street',
                fr:'Hmmm... Veuillez entrer une rue',
                ge:'Hmmm... Bitte geben Sie eine Straße ein'
            }
        },

        reg_city:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a city',
                fr:'Hummm... Veuillez entrer une ville',
                ge:'Hmmm... Bitte geben Sie eine Stadt ein'
            }
        },
        reg_birthday:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please select a birthday',
                fr:'Hmmm... Veuillez sélectionner une date de naissance',
                ge:'Hmmm... Bitte wählen Sie einen Geburtstag aus'
            }
        },


    },
    formsuccess_message: {
        en: 'Communicating with server',
        fr: 'Bienvenue sur eusi.ch',
        ge: 'Willkommen zurück auf eusi.ch'
    },
    server_error_message: {
        en: 'Username and password does not match',
        fr: 'Le nom d\'utilisateur et le mot de passe ne correspondent pas',
        ge: 'Benutzername und Passwort stimmen nicht überein'
    }

}