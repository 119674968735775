import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import React, {useState, useRef, useEffect} from 'react'
import {BrowserRouter as Router, Route, Routes, Link, useParams} from "react-router-dom";
import language_config from './modules/lang_config'
import HOME from './components/home.js';
import MENTORS from './components/mentors.js';
import ABOUT from './components/about.js';
import IMPRESSUM from './components/impressum.js';
import TEAM from './components/team.js';
import REGISTER from './components/register.js'
import ADDVIDEO from './components/addViedoes.js'
import VIDEOCATEGORY from './components/videocategory.js'
import VIDEOS from './components/videos.js'
import Signinmodal from './components/signinmodal.js'
import Footer from './components/footer.js'
import Emailverified from "./components/emailverified";
import Newpassword from './components/newpassword.js'
import DONATORS from './components/donators.js'
import SPONSORS from './components/sponsors.js'
import SPONSORING from './components/sponsoring.js'
import BANKING from './components/banking.js'
import PROFILE from './components/profile.js'
import {useCookies} from "react-cookie";
import reportWebVitals from "./reportWebVitals";
import {getRest} from "./modules/getRest";

let date = new Date()
function componentDidMount() {
    let script = document.createElement('script');
    script.src = "https://www.eusi.ch/js/script.js?reload=" + date.getTime()
    script.async = true;
    document.body.appendChild(script);
}
componentDidMount();

export default function App(props) {

    const TRACKING_ID = "G-WV73NXX1M0";
    ReactGA.initialize(TRACKING_ID);

    let pathname = window.location.pathname
    const [cookies, setCookie, removeCookie] = useCookies(["route", "session", "user"]);
    const [isloggedin, setLoggedInStatus] = useState(null);
    const [isAdmin, setIsAdmin] = useState((cookies.hasOwnProperty('user')) ? (cookies.user['videos'] === '1') : false );
    //const [activeRoute, setActive] = useState(null);
    let default_lang = (window.location.href.split('/')[4] === undefined) ? 'ge' : window.location.href.split('/')[4];
    const [language, setLanguage] = useState((cookies.hasOwnProperty('user'))? cookies.user['language'] : default_lang);
    const [show, setShow] = useState((pathname === '/signin'));

    console.log('language: ',default_lang);

    const childRef = useRef(null);
    const routeRef = useRef(null);
    const signinRef = useRef(null);

    //console.log('cookie setting : ' + 'isAdmin:  ' + isAdmin);

    useEffect(() => {
        if (cookies.hasOwnProperty('user')) {
            if(cookies.user.language !== undefined){
                if(language !== cookies.user.language){
                    changingLanguage(cookies.user.language);
                }
            }
            //setIsAdmin(cookies.user['videos'] === '1');
        } else {
            setLoggedInStatus(false);
        }
    }, []);


    if(cookies.hasOwnProperty('session')){
        if(cookies.session.length > 0){
            getRest('getloggedin/' + cookies.session).then(r => {
                if(r.result['loggedin']){
                    setLoggedInStatus(true);
                } else {
                    setLoggedInStatus(false);
                }
            }).catch(err => { const mute = err });
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const changingLanguage = (lang) =>{
        //console.log('this is hitting ')
        handleSetCookie(lang);
    };

    function handleSetCookie(lang) {
        let userObj = (cookies.hasOwnProperty('user')) ? cookies.user : {};
        userObj.language = lang

        setCookie("user", userObj, { path: '/' });
        window.location.href = `/language/${lang}`
    }

    const handleSignOut = () => {
        if(cookies.hasOwnProperty('user')){
            getRest('getloggedout/' + cookies.user.id).then(r => {
                if(r.hasOwnProperty('success')){

                }
            });
        }

        removeCookie('user');
        removeCookie('session');
        removeCookie('itemList');
        removeCookie('videoList');
        removeCookie('categoriesloaded');
        setLoggedInStatus(false);
        setIsAdmin(false);
        //window.location.href = '/';
    }

    const changelocation = (event) =>{
    }

    const navbarmain = {
        //backgroundColor: 'rgba(255, 255, 255, 0.5)'
        //color:'black',
        //opacity: '0.4'
    }


    const custom_dropdown = {
        width:'100%',
        height:'100%',
        border:'none',
        paddingLeft:'20px',
        paddingTop:'25px',
        paddingBottom:'25px',
        cursor:'pointer',
        textShadow:'0px 0px #ffffff'
    }

    const noshadow = {
        textShadow:'0px 0px #ffffff'
    }

    const noshowmenu = {
        opacity:'.2'
    }

    const showmenu = {
        opacity:'1'
    }

    return (
        <>
        <Router>
                {show && <Signinmodal show={show} onHide={() => handleClose(false)} ref={signinRef} language={language} />}
                <div className="rd-navbar-wrap">
                    <nav className="rd-navbar rd-navbar-classic rd-navbar-classic-1" data-layout="rd-navbar-fixed"
                         data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
                         data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static"
                         data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static"
                         data-xl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static"
                         data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="50px"
                         data-xl-stick-up-offset="1px" data-xxl-stick-up-offset="1px" data-lg-stick-up="true"
                         data-xl-stick-up="true" data-xxl-stick-up="true" data-lg-auto-height="false"
                         data-xl-auto-height="false" data-xxl-auto-height="false">


                        <div className={navbarmain + " rd-navbar-main-outer"} style={navbarmain}>
                            <div className="rd-navbar-main">
                                <div className="rd-navbar-panel">
                                    <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
                                        <span>&nbsp;</span>
                                    </button>
                                    <div className="rd-navbar-brand">
                                        <a className="brand" href="/">
                                            <img className="brand-logo-dark" src="https://www.eusi.ch/images/logo_diamond_text_middle.png" alt="" />
                                            <img className="brand-logo-light" src="https://www.eusi.ch/images/logo-inverse-234x84.png" alt="" width="334" height="110"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="mr-5 rd-navbar-nav-wrap">
                                    <ul className="rd-navbar-nav" >
                                        <li className="rd-nav-item">
                                            <Link className="rd-nav-link" to="/">{language_config.menu[language].mm1}</Link>
                                        </li>
                                        <li className="rd-nav-item">
                                            <Link className="rd-nav-link" to="/about">{language_config.menu[language].mm2}</Link>
                                        </li>
                                        <li className="rd-nav-item">
                                            <Link className="rd-nav-link" to="/team">{language_config.menu[language].mm3}</Link>
                                        </li>
                                        <li className="rd-nav-item">
                                            <Link className="rd-nav-link" to="/sponsors">{language_config.menu[language].mm11.main}</Link>
                                            <ul className="rd-menu rd-navbar-dropdown">
                                                <li className="rd-dropdown-item">
                                                    <Link className="rd-nav-link" style={noshadow} to="/donators">
                                                        {language_config.menu[language].mm11.sub1}
                                                    </Link>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <Link className="rd-nav-link" style={noshadow} to="/sponsors">
                                                        {language_config.menu[language].mm11.sub2}
                                                    </Link>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <Link className="rd-nav-link" style={noshadow} to="/sponsoring">
                                                        {language_config.menu[language].mm11.sub3}
                                                    </Link>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <Link className="rd-nav-link"style={noshadow} to="/bank">
                                                        {language_config.menu[language].mm11.sub4}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>


                                        {/*     <li className="rd-nav-item">
                                            <Link className="rd-nav-link" to="/">{language_config.menu[language].mm4}</Link>
                                            <ul className="rd-menu rd-navbar-dropdown">
                                                <li className="rd-dropdown-item">
                                                    <button className=" rd-nav-link text-left" style={custom_dropdown} onClick={() => changingLanguage('ge')}>Deutsch</button>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <button className="rd-nav-link text-left" style={custom_dropdown} onClick={() => changingLanguage('fr')}>Français</button>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <button className="rd-nav-link text-left" style={custom_dropdown} onClick={() => changingLanguage('it')}>Italiano</button>
                                                </li>
                                                <li className="rd-dropdown-item">
                                                    <button className="rd-nav-link text-left" style={custom_dropdown} onClick={() => changingLanguage('en')}>English</button>
                                                </li>
                                            </ul>
                                        </li> */}

                                        {isloggedin && <>
                                            <li className="rd-nav-item">
                                                <Link className="rd-nav-link" to="/videocategory">{language_config.menu[language].mm7}</Link>
                                            </li>
                                            <li className="rd-nav-item">
                                                <Link className="rd-nav-link" to="/">{language_config.menu[language].mm5}</Link>
                                                <ul className="rd-menu rd-navbar-dropdown">
                                                    <li className="rd-dropdown-item">
                                                        <Link className="rd-dropdown-link" to="/profile">{language_config.menu[language].mm6}</Link>
                                                    </li>
                                                    {isAdmin && <li className="rd-dropdown-item">
                                                        <Link className="rd-dropdown-link" to="/addvideo">{language_config.menu[language].mm8}</Link>
                                                    </li>}
                                                </ul>
                                            </li></>}

                                        {!isloggedin && <li className="rd-nav-item">
                                            <a className="rd-nav-link custom_main_menu" href={'#'} onClick={handleShow}>{language_config.menu[language].mm9}</a>
                                        </li>}
                                        {isloggedin && <li className="rd-nav-item">
                                            <a className="rd-nav-link" href={'#'} onClick={handleSignOut}>{language_config.menu[language].mm10}</a>
                                        </li>}
                                    </ul>
                                </div>
                                <button className="sidebar-toggle rd-navbar-fixed-element-1 mt-3 ml-auto mr-auto" data-rd-navbar-toggle=".sidebar-classic-wrap">
                                    <span>&nbsp;</span>
                                </button>
                            </div>
                        </div>
                        <div className="sidebar-classic-wrap">
                            <div className="sidebar-classic">
                                <ul className="contacts-classic">
                                    <li>{language_config.sidebar[language].poststory}
                                    </li>
                                    <li>Allmeindstrasse 17<br/>8840 Einsideln</li>
                                    <li><Link to="mailto:info@eusi-talent.ch">info@eusi-talent.ch</Link></li>
                                    <li className="rd-nav-item">
                                        <Link className="rd-nav-link" to="/impressum">{language_config.menu[language].mm12}</Link>
                                        <Link className="rd-nav-link" to="/impressum">{language_config.menu[language].mm13}</Link>
                                        <Link className="rd-nav-link" to="/images/terms.de.pdf">{language_config.menu[language].mm14}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebar-classic-panel">
                                <ul className="list-social list-inline list-inline-md">
                                    <li><Link className="icon mdi mdi-linkedin" to="https://www.linkedin.com">&nbsp;</Link></li>
                                    <li><Link className="icon mdi mdi-instagram" to="https://www.instagram.com/talent2pro/">&nbsp;</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="sidebar-classic-wrap">
                            <div className="sidebar-classic">
                                <ul className="contacts-classic">
                                    <li>{language_config.sidebar[language].poststory}
                                    </li>
                                    <li>Allmeindstrasse 17<br/>8840 Einsideln</li>
                                    <li><Link to="mailto:info@eusi-talent.ch">info@eusi-talent.ch</Link></li>
                                    <li className="rd-nav-item">
                                        <Link className="rd-nav-link" to="/impressum">{language_config.menu[language].mm12}</Link>
                                        <Link className="rd-nav-link" to="/impressum">{language_config.menu[language].mm13}</Link>
                                        <Link className="rd-nav-link" to="/images/terms.de.pdf">{language_config.menu[language].mm14}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebar-classic-panel">
                                <ul className="list-social list-inline list-inline-md">
                                    <li><Link className="icon mdi mdi-linkedin" to="https://www.linkedin.com">&nbsp;</Link></li>
                                    <li><Link className="icon mdi mdi-instagram" to="https://www.instagram.com/talent2pro/">&nbsp;</Link></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                    <div key={'123456789'}>
                        <Routes>
                            <Route path="/" element={<HOME ref={routeRef} language={language}/>} />
                            <Route path="/language/:lang" element={<HOME ref={routeRef} language={language}/>} />
                            <Route path="/:id" element={<HOME ref={routeRef} language={language}/>} />
                            <Route path="/mentors" element={<MENTORS ref={routeRef} language={language}/>}/>
                            <Route path="/about" element={<ABOUT ref={routeRef} language={language}/>}/>
                            <Route path="/impressum" element={<IMPRESSUM ref={routeRef} language={language}/>}/>
                            <Route path="/team" element={<TEAM ref={routeRef} language={language}/>}/>
                            <Route path="/register" element={<REGISTER ref={routeRef} language={language}/>}/>
                            <Route path="/profile" element={<PROFILE ref={routeRef} language={language}/>}/>
                            <Route path="/emailverified" element={<Emailverified ref={routeRef} language={language}/>}/>
                            <Route path="/newpassword/:url" element={<Newpassword ref={routeRef} language={language}/>}/>
                            <Route path="/donators" element={<DONATORS ref={routeRef} language={language}/>}/>
                            <Route path="/sponsors" element={<SPONSORS ref={routeRef} language={language}/>}/>
                            <Route path="/sponsoring" element={<SPONSORING ref={routeRef} language={language}/>}/>
                            <Route path="/bank" element={<BANKING ref={routeRef} language={language}/>}/>
                            {isloggedin && <Route path="/videocategory" element={<VIDEOCATEGORY ref={routeRef} language={language}/>}/>}
                            {isloggedin && <Route path="/videos/:url" element={<VIDEOS ref={routeRef} language={language}/>}/>}
                            {isAdmin && <Route path="/addvideo" element={<ADDVIDEO ref={routeRef} language={language}/>}/>}
                        </Routes>
                    </div>
            <Footer ref={childRef} language={language}/>
        </Router>
        </>
    );
}



const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<App />);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

