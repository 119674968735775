/**import axios from 'axios'
module.exports = async function axioPostRequest(obj, destination) {

    //let params = obj;
    //return await axios.post(destination, params);
    return await axios({
        method: "POST",
        url: destination,
        dataType: "JSON",
        withcredentials: true,
        body: obj
    })
    //console.log(res.data);
}*/


export async function postRest(obj, destination){
    let url = destination;
    let options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    };
    let response = await fetch(url, options);
    let responseOK = response && response.ok;
    if (response) {
        let data = await response.json();
        //console.log(response);
        return data;
    }
}