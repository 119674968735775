import React from "react";
import {Link} from "react-router-dom";
import background1 from "../../src/assets/images/ski_community.jpg";
import lang_sponsoring from "../modules/lang_sponsoring";
const slideStyle1={ backgroundImage: `url(${background1})`};

class sponsoring extends React.Component {

    constructor(props){
        super(props);
        this.custom_text_style = {
            whiteSpace: 'pre-line'
        }
        this.pdflink = "<a href='https://www.vg.no'>(DOWNLOAD BRANDING PDF ‘EUSI presentation’)</a>";
    }

    render() {

        const check = 'https://www.eusi.ch/images/icons/check.png';
        const checkstyle = { width:'20px' };
        const rowbordertop = {borderTop:'1px double white'}

        return (
            <>

            <style>

            </style>
            <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section section-xl bg-default text-left">
                <div className="container">
                    <div className="inset-xl-left-10 mb-5">
                        <h3>{lang_sponsoring.headline[this.props.language]}</h3>
                        <p className="big" style={this.custom_text_style} >
                            { lang_sponsoring.story[this.props.language].part1}
                            <a href="https://www.eusi.ch/pdf/presentation" target="_blank">{ lang_sponsoring.story[this.props.language].part2}</a>&nbsp;<br/>
                            { lang_sponsoring.story[this.props.language].part3}<br/>
                            <a href="https://www.eusi.ch/pdf/platformsponsors" target="_blank">{ lang_sponsoring.story[this.props.language].part4}</a><br/>
                            { lang_sponsoring.story[this.props.language].part5}<br/>
                        </p>
                        <p className="big"><a href="mailto:sponsoring@eusi-talent.ch">{lang_sponsoring.footer[this.props.language]}</a></p>
                    </div>
                </div>
            </section>
        </>

        )
    }
}

export default sponsoring

