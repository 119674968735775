export function formaudit(form){
    let auditok = true;
    let error_msg = '';
    let returnobj = {};

    const string_length_audit = (string) => {
        return (string.length > 0);
    };
    const email_audit = (email) => {
        const emailexpr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!(emailexpr.test(email.toString().toLowerCase()));
    };

    const pass_audit = (pass) => {
        const decimal=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
        return !!(pass.match(decimal));
    }

    const select_audit = (select) => {
        return (select.length > 0 || select > 0);
    }

    for (const [key, value] of Object.entries(form.form)) {
        console.log(key + ':' + value.type+":"+value.value);
        if(value.required){
            // eslint-disable-next-line default-case
            switch (value.type) {
                case 'email':
                    auditok = email_audit(value.value);
                    returnobj[key] = value.value;
                    break;
                case 'string':
                    auditok = string_length_audit(value.value);
                    returnobj[key] = value.value;
                    break;
                case 'password':
                    auditok = pass_audit(value.value);
                    returnobj[key] = value.value;
                    break;
                case 'select':
                    auditok = select_audit(value.value);
                    console.log(auditok)
                    returnobj[key] = value.value;
                    break;
                case 'passpass':
                    console.log('value one', form.form[value.ref].value)
                    auditok = (value.value === form.form[value.ref].value)
                    returnobj[key] = value.value;
                    break;
            }
        }
        if(!auditok){
            return {
                'success': false,
                'message': value.err_message
            }
        }
    }

    if(auditok){
        return{
            'success': true,
            'message': form.formsuccess_message,
            'post': returnobj
        }
    }
};