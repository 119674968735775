module.exports = global.videoObjectValidation = {
    form: {
        v_id: {
            'value': 'default',
            'required': true,
            'type': 'string',
            placeholder: {
                en: '',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a valid id',
                fr: 'Hmmm... Veuillez entrer un nom de valide',
                ge: 'Hmmm... Bitte geben Sie einen gültigen namen ein'
            }
        },
        v_parent: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: '',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a valid category',
                fr: 'Hmmm... Veuillez entrer un nom de valide',
                ge: 'Hmmm... Bitte geben Sie einen gültigen namen ein'
            }
        },
        v_youtube: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'Youtube link',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a valid youtube link',
                fr: 'Hmmm... Veuillez entrer un nom de famille valide',
                ge: 'Hmmm... Bitte geben Sie einen gültigen Familiennamen ein'
            }
        },
        v_title_en: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'English Title',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a English Title',
                fr: 'Hummm... Veuillez saisir une adresse e-mail valide',
                ge: 'Hmmm... Bitte geben Sie eine gültige E-Mail-Adresse ein'
            }
        },
        v_title_fr: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'French Title',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a French Title',
                fr: 'Hum... Veuillez entrer un mot de passe valide',
                ge: 'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        },
        v_title_ge: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'German Title',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a German Title',
                fr: 'Hum... Veuillez entrer un mot de passe valide',
                ge: 'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        },
        v_title_it: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'Italian Title',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a Italian Title',
                fr: 'Hum... Veuillez entrer un mot de passe valide',
                ge: 'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        },
        v_desc_en: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'English Description',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a English description',
                fr: 'Hmmm... Veuillez entrer une rue',
                ge: 'Hmmm... Bitte geben Sie eine Straße ein'
            }
        },
        v_desc_fr: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'French Description',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a French description',
                fr: 'Hmmm... Veuillez entrer une rue',
                ge: 'Hmmm... Bitte geben Sie eine Straße ein'
            }
        },
        v_desc_ge: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'German Description',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a German description',
                fr: 'Hmmm... Veuillez entrer une rue',
                ge: 'Hmmm... Bitte geben Sie eine Straße ein'
            }
        },
        v_desc_it: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'Italian Description',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please enter a Italian description',
                fr: 'Hmmm... Veuillez entrer une rue',
                ge: 'Hmmm... Bitte geben Sie eine Straße ein'
            }
        },
        v_mentor: {
            'value': '',
            'required': true,
            'type': 'string',
            placeholder: {
                en: 'Mentors name',
                fr: '',
                ge: ''
            },
            err_message: {
                en: 'Hmmm... Please the name of the Mentor',
                fr: 'Hummm... Veuillez entrer une ville',
                ge: 'Hmmm... Bitte geben Sie eine Stadt ein'
            }
        }
    },
    button: {
        'value': '',
        'required': false,
        'type': 'button',
        'name': {
            en: 'Submit data',
            fr: '',
            ge: ''
        }
    }
}