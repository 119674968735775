module.exports = global.lang_sponsoring = {
    title1:{
        ge:'Präsident',
        fr:'Président',
        it:'Presidente',
        en:'President'
    },
    text1:{
        ge:'Ich studiere Sport Management an der Fachhochschule Graubünden. Meine grosse ' +
            'Leidenschaft ist der Fussball wo man unter hohem Leistungsdruck steht und ' +
            'links liegengelassen wird, wenn man nicht gut genug ist. Mit Eusi Talent wollen ' +
            'wir junge Sportler und Sportlerinnen durch das Bereitstellen von Know-How - ' +
            'von neutralen Experten, Mentoren und Mentorinnen vermittelt – unterstützen, ' +
            'damit sie selber entscheiden zu können, ob Sie ein Talent sind und wieviel Zeit ' +
            'sie für eine Sportkarriere zu investieren bereit sind.',
        fr:'J\'étudie le management du sport à l\'Ecole Universitaire Professionale des Grisons. ' +
            'Ma grande passion est le football, où l\'on est soumis à une forte pression et où ' +
            'l\'on est laissé de côté si l\'on n\'est pas assez bon. Avec Notre Talents, nous ' +
            'voulons soutenir les jeunes sportifs et sportives en mettant à leur disposition ' +
            'un savoir-faire transmis par des experts neutres, des mentors, afin qu\'ils puissent ' +
            'décider eux-mêmes s\'ils sont des talents et combien de temps ils sont prêts à investir ' +
            'dans une carrière sportive.',
        it:'Studio Sport Management presso la Scuola Universitaria Professionale dei Grigioni. La mia ' +
            'grande passione è il calcio, dove si è sottoposti a una forte pressione per ottenere ' +
            'risultati e si viene esclusi se non si è abbastanza bravi. Con Nostri Talenti ' +
            'vogliamo sostenere i giovani atleti fornendo loro un know-how - trasmesso da esperti ' +
            'neutrali, mentori e tutori - affinché possano decidere da soli se sono un talento e ' +
            'quanto tempo sono disposti a investire in una carriera sportiva.',
        en:'I study Sport Management at the University of Applied Sciences Graubünden. ' +
            'My great passion is football where you are under high pressure to perform ' +
            'and are left out if you are not good enough. With Eusi Talent we want ' +
            'to support young athletes by providing know-how - conveyed by unbiased experts ' +
            'and mentors- so that they can decide for themselves what talents they have and ' +
            'how much time they are willing to invest in a sports career.'
    },

    title2:{
        ge:'Kassier & Finanzen',
        fr:'Trésorier & Finances',
        it:'Tesoriere e finanze',
        en:'Treasurer & Finances'
    },
    text2:{
        ge:'Ich wandere gerne und im Winter fahre ich Ski, auch weil ich dabei Zeit mit Freunden ' +
            'verbringen kann. Es ist wunderbar von einer Bergspitze aus in die Welt zu schauen. ' +
            'Bei Eusi Talent bringe ich meine Erfahrung als Buchhalter ein, weil es wichtig ist, ' +
            'dass der Verein finanziell gesund dasteht, aber auch weil Sportler werden viel Geld kostet. ' +
            'Ein guter Plan und das richtige Wissen helfen dabei.',
        fr:'J\'aime la randonnée et en hiver je fais du ski, aussi parce que je peux passer du ' +
            'temps avec des amis. C\'est merveilleux de voir le monde du haut d\'une montagne.\n' +
            '\n' +
            'J\'apporte mon expérience de comptable à Nos Talents car il est important que l’association ' +
            'soit financièrement saine, mais aussi parce que devenir athlète coûte très cher. Un bon plan et les ' +
            'bonnes connaissances vous aideront.\n',
        it:'Mi piace fare escursioni e in inverno scio, anche perché posso passare del tempo ' +
            'con gli amici. È meraviglioso vedere il mondo dalla cima di una montagna. Porto la ' +
            'mia esperienza come commercialista in Nostri Talenti perché è importante che l’associazione ' +
            'sia finanziariamente in buona salute, ma anche perché diventare un atleta costa ' +
            'un sacco di soldi. Un buon piano e la giusta conoscenza aiuteranno.',
        en:'I like hiking and in winter I ski, also because I can spend time with friends. It\'s ' +
            'wonderful to see the world from the top of a mountain. I bring my experience as an accountant ' +
            'to Our Talents because it is important that the association is financially healthy, but ' +
            'also because becoming an athlete costs a lot of money. A good plan and the right knowledge will help.'
    },

    title3:{
        ge:'Mitglied',
        fr:'Membre',
        it:'Membro',
        en:'Member'
    },
    text3:{
        ge:'Ich stehe mitten im Studium an der ETH Zürich (Gesundheitswissenschaften und ' +
            'Technologie). Meine Sportarten sind Volleyball (VBC Einsiedeln), Beachvolleyball, ' +
            'ich fahre gerne Ski Alpin und bin auch beim Klettern in der Halle und in der Natur ' +
            'anzutreffen. Ich engagiere mich bei Eusi Talent, weil ich dank meiner Ausbildung ' +
            'weiss, wie wichtig eine richtige Ernährung, genügend Schlaf und Muskelregeneration ' +
            'für uns alle, aber besonders für junge Sportler und Sportlerinnen sind.',
        fr:'J\'étudie les sciences et technologies de la santé à l\'ETH Zurich. Mon sport préféré est ' +
            'le volley-ball (VBC Einsiedeln), ainsi que le volley-ball de plage. J\'aime le ski ' +
            'alpin et je suis également actif en escalade indoor et outdoor. Je fais partie d\'Eusi ' +
            'Talent parce que, grâce à mon entraînement, je sais combien une alimentation correcte, ' +
            'un sommeil suffisant et la récupération musculaire sont importants pour nous tous, ' +
            'mais surtout pour les jeunes athlètes.',
        it:'Studio scienze della salute e tecnologie al ETH Zurigo. Il mio sport preferito è la ' +
            'pallavolo (VBC Einsiedeln), così come il beach volley. Mi piace lo sci alpino e sono ' +
            'anche un\'attiva arrampicatrice indoor e su roccia. Faccio parte di Eusi Talent perché ' +
            'grazie alla mia formazione so quanto siano importanti un\'alimentazione corretta, ' +
            'un sonno sufficiente e il recupero muscolare per tutti noi, ma soprattutto per i giovani atleti.',
        en:'I study health sciences and technology at ETH Zurich. My favorite sport is volleyball ' +
            '(VBC Einsiedeln), as well as beach volleyball. I enjoy downhill skiing and am also an ' +
            'active indoor and rock climber. I am part of Eusi Talent because through my training ' +
            'I know how important proper nutrition, sufficient sleep and muscle recovery ' +
            'are for all of us, but especially for young athletes.',
    },

    title4:{
        ge:'Mitglied',
        fr:'Membre',
        it:'Membro',
        en:'Member'
    },
    text4:{
        ge:'Hauptberuflich bin ich Skirennfahrer mit Fokus auf Abfahrt und Super-G (RLZ Hoch-Ybrig, ' +
            'SC Feusisberg). Und zum Ausgleich klettere ich gerne. Wenn ich nicht Rennen fahre oder ' +
            'Trainiere, arbeite ich als Forstwart. Ich mache aktiv mit bei Eusi Talent, da ich als ' +
            'Leistungssportler nur zu gut weiss, wie wichtig Zugang zum richtigen Know-How ist, um ' +
            'im Sport erfolgreich sein zu können.',
        fr:'Mon activité principale est le ski de compétition, avec un accent sur la descente et ' +
            'le super-G (RLZ Hoch-Ybrig, SC Feusisberg). Et pour compenser, j\'aime faire de l\'escalade. ' +
            'Quand je ne fais pas de course ou que je ne m\'entraîne pas, je travaille comme garde forestier. Je ' +
            'participe activement à Eusi Talent, car en tant que sportif de haut niveau, ' +
            'je ne sais que trop bien à quel point il est important d\'avoir accès au bon savoir-faire pour ' +
            'pouvoir réussir dans le sport.',
        it:'Mi piace fare escursioni e in inverno scio, anche perché posso passare del tempo ' +
            'con gli amici. È meraviglioso vedere il mondo dalla cima di una montagna. Porto la ' +
            'mia esperienza come commercialista in Nostri Talenti perché è importante che l’associazione ' +
            'sia finanziariamente in buona salute, ma anche perché diventare un atleta costa ' +
            'un sacco di soldi. Un buon piano e la giusta conoscenza aiuteranno.',
        en:'I race ski for a living with a focus on Downhill and Super-G racing (RLZ Hoch-Ybrig, SC Feusisberg). ' +
            'In my free time I like to climb. When I\'m not racing or training, I work as a forest warden. ' +
            'I participate in Eusi Talent, because as a competitive athlete I know only too well how ' +
            'important access to the right know-how is to be successful in sports.',
    }
}
