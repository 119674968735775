module.exports = global.lang_register = {
    subheadline:{
        en:'Dedicated to inspiering excellence',
        fr:'Dédié à l`excellence inspirante',
        ge:'Der inspirierenden Exzellenz verschrieben',
        it:'Dedicato all\'eccellenza ispiratrice'
    },
    regheadline:{
        en:'Registration',
        fr:'S`inscrire',
        ge:'Registrieren',
        it:'Registrazione'
    },
    regingress:{
        en:'Access our playlists in a few seconds',
        fr:'Accédez à nos playlists en quelques secondes',
        ge:'Zugriff auf unsere Playlists in wenigen Sekunden',
        it:'Accedi alle nostre playlist in pochi secondi'
    },
    form:{
        name:{
            placeholder:{
                en:'First Name',
                fr:'Prenom',
                ge:'Vornamen',
                it:'Nome di battesimo'
            }
        },
        fname:{
            placeholder:{
                en:'Familiy Name',
                fr:'Nom de famille',
                ge:'Nachnamen',
                it:'Cognome'
            }
        },
        username:{
            placeholder:{
                en:'Username - Email',
                fr:'Nom d`utilisateur - E-mail',
                ge:'Benutzername - E-Mail',
                it:'Nome utente - E-mail'
            }
        },
        password:{
            placeholder:{
                en:'Password',
                fr:'Passwort',
                ge:'Mot du passe',
                it:'Parola d\'ordine'
            },
            label:{
                en:'Set password; minimum 8-12:(char), 1:(0-9), 1:(!%&#) ',
                fr:'Définir le mot de passe; minimum 8-12:(caractères), 1:(0-9), 1:(!%&#)',
                ge:'Passwort festlegen; mindestens 8-12:(Zeichen), 1:(0-9), 1:(!%&#)',
                it:'Impostare la password; minimo 8-12:(char), 1:(0-9), 1:(!%\'s)'
            }
        },
        street:{
            placeholder:{
                en:'Street',
                fr:'Rue',
                ge:'Straße',
                it:'Strada'
            }
        },
        phone:{
            placeholder:{
                en:'Cell Phone',
                fr:'Téléphone Mobile',
                ge:'Mobiltelefon',
                it:'Telefono Cellulare'
            }
        },
        sport:{
            placeholder:{
                en:'Sport',
                fr:'Sport',
                ge:'Sport',
                it:'Sport'
            },
            en:[
                {value:'', label:'Please select your sport'},
                {value:1, label:'Football'},
                {value:2, label:'Winter Sports'},
                {value:3, label:'Volleyball/Beach Volleyball'},
                {value:4, label:'Basketball'},
                {value:5, label:'Athletics'},
                {value:6, label:'Running'},
                {value:7, label:'Mountain Bike/Bike Racing'},
                {value:8, label:'Ice Hockey'},
                {value:9, label:'Other'}
            ],
            ge:[
                {value:'', label:'Bitte wählen Sie Ihre Sportart aus'},
                {value:1, label:'Fussball'},
                {value:2, label:'Wintersport'},
                {value:3, label:'Volleyball/Beach Volleyball'},
                {value:4, label:'Basketball'},
                {value:5, label:'Leichtathletik'},
                {value:6, label:'Laufsport'},
                {value:7, label:'Mountain Bike/Velorennen'},
                {value:8, label:'Eishockey'},
                {value:9, label:'andere'}
            ],
            it:[
                {value:'', label:'Seleziona il tuo sport'},
                {value:1, label:'Calcio'},
                {value:2, label:'Sport invernali'},
                {value:3, label:'Pallavolo/beach volley'},
                {value:4, label:'Basket'},
                {value:5, label:'Atletica'},
                {value:6, label:'Corsa'},
                {value:7, label:'Mountain bike/ciclismo'},
                {value:8, label:'Hockey su ghiaccio'},
                {value:9, label:'Altri'}
            ],
            fr:[
                {value:'', label:'Veuillez sélectionner votre sport'},
                {value:1, label:'Football'},
                {value:2, label:'Sports d`hiver'},
                {value:3, label:'Volley-ball/Beach-volley'},
                {value:4, label:'Basket-ball'},
                {value:5, label:'Athlétisme'},
                {value:6, label:'Course à pied'},
                {value:7, label:'VTT/Vélo de route'},
                {value:8, label:'Hockey sur glace'},
                {value:9, label:'Autres'}
            ]
        },
        langprefrence:{
            label:{
                en:'Select language preference',
                fr:'Sélectionnez la préférence de langue',
                ge:'Spracheinstellung auswählen',
                it:'Seleziona la lingua preferita'
            }
        },
        city:{
            placeholder:{
                en:'Postcode and city',
                fr:'Code postale et ville',
                ge:'Postleitzahl und Stadt',
                it:'Codice postale e città'
            }
        },
        birthday:{
            placeholder:{
                en:'Birthday',
                fr:'Anniversaire',
                ge:'Geburtstag',
                it:'Compleanno'
            },
            label:{
                en:'Please select your birthday',
                fr:'Veuillez sélectionner votre anniversaire',
                ge:'Bitte Geburtstag auswählen',
                it:'Seleziona il tuo compleanno'

            }
        },

        button:{
            en:'Submit',
            fr:'Sauer',
            ge:'Speichern',
            it:'Invia'
        }
    }
}