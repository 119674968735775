export async function patchRest(obj, destination){
    let url = destination;
    let options = {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
    };
    let response = await fetch(url, options);
    //let responseOK = response && response.ok;
    if (response) {

        let data = await response.json();
        ///console.log(data);

        //if(data.hasOwnProperty('result')){
        //    let result = data.result;
        //}
        //console.log(response);
        return data;
    }
}