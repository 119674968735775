module.exports = global.subcats = {

        'football': {
            'en': ['FIFA und SFV Regeln'],
            'ge': ['FIFA und SFV Regeln','Verträge ','Kraft & Ausdauer'],
            'it': ['FIFA und SFV Regeln'],
            'fr': ['FIFA und SFV Regeln']
        },
        'wintersport': {
            'en': ['FIFA und SFV Regeln'],
            'ge': ['FIFA und SFV Regeln','Verträge ','Kraft & Ausdauer'],
            'it': ['FIFA und SFV Regeln'],
            'fr': ['FIFA und SFV Regeln']
        }
}
