import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {getRest} from '../modules/getRest.js'
import {useCookies} from "react-cookie";

import {Link} from "react-router-dom";
import background1 from "../assets/images/swiper-1-slide-1.jpg";
const slideStyle1={ backgroundImage: `url(${background1})`};

function Videocategory(props, ref){

    const [cookies, setCookie, removeCookie] = useCookies(["language", "route", "user"]);
    const [language, setLanguage] = useState((props.language) ? props.language : 'en');
    const [catLoaded, setCatsloaded] = useState();
    let rows = [];
    let list = cookies.user['videocats']

    const headlines = {
        en:'Video content',
        ge:'Videoinhalte',
        fr:'Contenu vidéo',
        it:'Contenuti video'
    }

    if(cookies.hasOwnProperty('user')){
        //list = cookies.user['videocats'];
        renderList();
    }

    const gotoVideos = (event) =>{
        window.location.href = event.id;
    }

    function renderList(){
        for (let i = 0; i < list.length; i++) {
            let url = '/videos/' + list[i]['url'];
            let icon = 'https://www.eusi.ch/images/icons/' + list[i]['icon']
            rows.push(<div className="col-sm-6 col-lg-4" key={i} >

                            <article className="box-minimal">
                                <a href={url}>
                                    <div className="unit align-items-center">
                                        <div className="unit-left">
                                            <img src={icon}  width="50" height="60" />
                                        </div>
                                        <div className="unit-body">
                                            <div className="heading-4 box-minimal-title">{list[i]['name_' + language]}</div>
                                        </div>
                                    </div>
                                    <div className="heading-5 box-minimal-number ordered-item">&nbsp;</div>
                                </a>
                            </article>

                    </div>)


            //rows.push(<div key={i} className="col-sm-4 col-lg-3"><Link className="" to={url}><img className="img-border-stnd" src={icon} alt="" width="270" height="200"/></Link><br/><h6>{list[i]['name_en']}</h6>< /div>);
        }
    }


    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));



    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">&nbsp;</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>Videos</span></li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="section text-center">
                <div className="section-md bg-default section-decor section-decor-left">
                    <div className="container">
                        <div className="heading-2 box-minimal-title">{headlines[language]}</div>
                        <div className="row row-xl no-gutters bordered-2 ordered-1 wow fadeInRight">
                            {rows}
                        </div>
                    </div>
                </div>
            </section>



        </>
    );
}

export default forwardRef(Videocategory);