module.exports = global.signObjectValidation = {
    form: {
        reg_email: {
            'value': '',
            'required': true,
            'type': 'email',
            err_message: {
                en: 'Hmmm... Please enter a valid e-mail',
                fr: 'Hummm... Veuillez saisir une adresse e-mail valide',
                ge: 'Hmmm... Bitte geben Sie eine gültige E-Mail-Adresse ein'
            }
        },
        reg_pass: {
            'value': '',
            'required': true,
            'type': 'password',
            err_message: {
                en: 'Hmmm... Please enter a valid password',
                fr: 'Hum... Veuillez entrer un mot de passe valide',
                ge: 'Hmmm... Bitte geben Sie ein gültiges Passwort ein'
            }
        }
    },
    formsuccess_message: {
        en: 'Communicating with server',
        fr: 'Bienvenue sur eusi.ch',
        ge: 'Willkommen zurück auf eusi.ch'
    },
    server_error_message: {
        en: 'Username and password does not match',
        fr: 'Le nom d\'utilisateur et le mot de passe ne correspondent pas',
        ge: 'Benutzername und Passwort stimmen nicht überein'
    }
}