module.exports = global.language_config = {
    i18n: {
        welcome: {
            en: "Welcome",
            fr: "Bienvenue",
            ge: "Willkommen",
            rr: "Willkommen"
        }
    },
    languageSelected: 'en',
    menu: {
        en: {
            mm1: 'Home',
            mm2: 'About',
            mm3: 'Team',
            mm4: 'Languages',
            mm5: 'My pages',
            mm6: 'Profile',
            mm7: 'Playlists',
            mm8: 'Admin',
            mm9: 'Login',
            mm10: 'Logout',
            mm11:{
                main: 'Sponsoring',
                sub1: 'Our Donors',
                sub2: 'Our Sponsors',
                sub3: 'Sponsoring',
                sub4: 'Bank Detail'
            },
            mm12: 'IMPRINT',
            mm13: 'PRIVACY POLICY',
            mm14: 'TERMS OF USE'
        },
        fr: {
            mm1: 'Home',
            mm2: 'A propos de nous',
            mm3: 'Equipe',
            mm4: 'Langues',
            mm5: 'Mes pages',
            mm6: 'Profile',
            mm7: 'Videos',
            mm8: 'Admin',
            mm9: 'Se connecter',
            mm10: 'Se deconnecter',
            mm11:{
                main: 'Sponsoring',
                sub1: 'Donateurs',
                sub2: 'Sponsors',
                sub3: 'Sponsoring',
                sub4: 'Coordonnées Bancaires'
            },
            mm12: 'IMPRESSION',
            mm13: 'PROTECTION DES DONNÉES',
            mm14: 'CONDITIONS D\'UTILISATION'
        },
        ge: {
            mm1: 'Home',
            mm2: 'Eusi idee',
            mm3: 'Team',
            mm4: 'Sprachen',
            mm5: 'Meine seiten',
            mm6: 'Profil',
            mm7: 'Playlisten',
            mm8: 'Admin',
            mm9: 'Anmelden',
            mm10: 'Ausloggen',
            mm11:{
                main: 'Sponsoring',
                sub1: 'Eusi Gönner',
                sub2: 'Eusi Sponsoren',
                sub3: 'Sponsoring',
                sub4: 'Bank Details'
            },
            mm12: 'IMPRESSUM',
            mm13: 'DATENSCHUTZ',
            mm14: 'NUTZUNGSBEDINGUNGEN'
        },
        it: {
            mm1: 'Pagina Iniziale',
            mm2: 'Nostra Idea',
            mm3: 'Chi Siamo',
            mm4: 'Lingua',
            mm5: 'I miei siti',
            mm6: 'Profili',
            mm7: 'Elenco di riproduzione',
            mm8: 'Amministratore',
            mm9: 'Accedere',
            mm10: 'Disconnettersi',
            mm11:{
                main: 'Sponsoring',
                sub1: 'I Nostri Donatori',
                sub2: 'I Nostri Sponsor',
                sub3: 'Sponsoring',
                sub4: 'Coordinate Bancarie'
            },
            mm12: 'IMPRONTA',
            mm13: 'PROTEZIONE DEI DATI',
            mm14: 'TERMINI DI UTILIZZO'
        },
    },

    sidebar:{
        ge:{
            headline:'Die E-Learning Plattform für junge Sporttalente',
            poststory:'Verein zu Förderung von Sporttalenten in der Schweiz\n'
        },
        en:{
            headline:'The E-Learning Platform for Young Athletes',
            poststory:'Association for the promotion of sports talents in Switzerland'
        },
        fr:{
            headline:'La site web d`apprentissage en ligne pour les jeunes talents sportifs',
            poststory:'Association pour la Promotion des Talents Sportifs en Suisse'
        },
        it:{
            headline:'La piattaforma di e-learning per i giovani talenti dello sport’',
            poststory:'Associazione per la Promozione dei Talenti Sportivi in Svizzera'
        }
    },



    footer: {
        about: {
            en: {
                h1: 'ABOUT US',
                h2: 'Eusi Talent is operated by the Association for the Promotion of Sports Talent in Switzerland, ' +
                    'which aims to promote and support young athletes in Switzerland.'
            },
            fr: {
                h1: 'À PROPOS DE NOUS',
                h2: 'Nos Talents est géré par l\'Association pour la promotion des talents sportifs en ' +
                    'Suisse, qui a pour but d\'encourager et de soutenir les jeunes sportifs en Suisse.'
            },
            ge: {
                h1: 'ÜBER UNS',
                h2: 'Eusi Talent wird betrieben vom Verein zur Förderung von Sporttalenten in der Schweiz, ' +
                    'der die Förderung und Unterstützung junger Sportler in der Schweiz bezweckt.'
            },
            it: {
                h1: 'ÜBER UNS',
                h2: 'Nostri Talenti è gestito dall\'Associazione per la promozione dei talenti sportivi in Svizzera, ' +
                    'che mira a promuovere e sostenere i giovani atleti in Svizzera.'
            },

        },
        newsletter: {
            en: {
                h1: 'NEWSLETTER',
                h2: 'Enter your email',
                h3: 'Subscribe'
            },
            fr: {
                h1: 'BULLETIN',
                h2: 'Entrer votre Email',
                h3: 'S`abonner'
            },
            ge: {
                h1: 'NEWSLETTER',
                h2: 'E-Mail Adresse',
                h3: 'Abonnieren'
            },
            it: {
                h1: 'NEWSLETTER',
                h2: 'E-Mail Adresse',
                h3: 'Abonnieren'
            } ,
        },
        contacts: {
            en: 'CONTACT',
            fr: 'CONTACTER',
            ge: 'KONTAKT',
            it: 'CONTACT'
        }
    },

    // other global config variables you wish
};