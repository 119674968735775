import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
//import {useCookies} from "react-cookie";
import langobj from '../modules/lang_resetpass.js'
import lang_config from '../modules/lang_config.js'
import newpasswordObjectValidation from '../modules/newpasswordObjectValidation'
import {formaudit} from '../modules/formcheck.js'
import {patchRest} from "../modules/patchRest";
import background1 from "../../src/assets/images/swiper-1-slide-1.jpg";

const slideStyle1={ backgroundImage: `url(${background1})`};

const Newpassword = (props, ref) =>{

    const [language, setLanguage] = useState((props.language) ? props.language : lang_config.languageSelected);
    const [e_message, setErrorMessage] = useState({});
    const resetPassObject = newpasswordObjectValidation;
    const navigate = useNavigate();
    const url = useParams();

    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    const handleChange = (event) => {
        resetPassObject.form[event.target.id].value = event.target.value;
    }

    const handleCheckPassForm = () => {
        let formcheck = formaudit(resetPassObject);
        console.log('formcheck', formcheck);
        setErrorMessage(formcheck.message);
        if(formcheck.success === true){
            /** Below:miss leading function call, it just uses the same label as error message for displaying success  */
            /** Speak to the server */
            let params = {'reg_pass1':resetPassObject.form.reg_pass1.value, 'vstring':url.url}
            //console.log('params ', params);
            patchRest(params, 'https://www.eusi.ch/webservice/newpassword').then(r => {
                console.log('result', r)
                if(r.hasOwnProperty('result')){
                    if(r.result.updated === true){
                        setErrorMessage(resetPassObject.server_success_message);
                        window.location.href = '/signin';

                        // Navigate to some login page.
                    } else {
                        setErrorMessage(resetPassObject.server_error_message);
                    }
                }
            });
        }
    }

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">{langobj.headline[language]}</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>{langobj.subheadline[language]}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-lg bg-default">
                <div className="container">
                    <div className="row row-30 col-md-12 d-flex justify-content-center" >
                        <div className=" col-md-8 col-xl-8">
                            <h4>{langobj.ingress[language]}</h4>
                        </div>
                        <div className=" col-md-8 col-xl-8">
                            <form className="rd-form rd-mailform rd-form-centered">
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_pass1" type="password" name="email" placeholder={resetPassObject.form.reg_pass1.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_pass2" type="password" name="email" placeholder={resetPassObject.form.reg_pass2.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div>
                                    <p className="error">{e_message[language]}</p>
                                </div>
                                <div className="form-button">
                                    <button className="button button-gray-100" type="button" onClick={handleCheckPassForm}>{resetPassObject.form.button.name[language]}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default forwardRef(Newpassword)