import React, {useState, forwardRef, useImperativeHandle} from 'react';
import background1 from "../../src/assets/images/swiper-1-slide-1.jpg";
import {useCookies} from "react-cookie";
const slideStyle1={ backgroundImage: `url(${background1})`};
const image_dir = "https://www.eusi.ch/images/";


const mentors = forwardRef((props, ref) => {

    const [cookies, setCookie, removeCookie] = useCookies(["language","route"]);
    setCookie("route", 'about', { path: '/' });
    const [language, setLanguage] = useState(cookies.language);

    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));


    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">Mentors</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>Dedicated to inspiering excellence</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default">
                <div className="container">
                    <div className="row row-30 row-lg-50">
                        <div className="col-md-6">
                            <article className="video">
                                <img className="video-image" src="https://www.eusi.ch/images/video-1-549x384.jpg" alt="" width="549" height="384"/>
                                <a className="video-link" href="https://www.youtube.com/watch?v=1UWpbtUupQQ" data-lightgallery="item">
                                    <span className="icon mdi mdi-play"></span>
                                </a>
                            </article>
                        </div>
                        <div className="col-md-6">
                            <div className="inset-xl-left-70">
                                <h2>Our Mission</h2>
                                <article className="quote-minimal">
                                    <div className="heading-4 quote-minimal-text">
                                        <p className="q">We aim to provide the community with quality fitness and sport
                                            events.</p>
                                    </div>
                                </article>
                                <p className="big">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Adipiscing commodo elit at imperdiet
                                    dui. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eget dolor morbi non
                                    arcu risus quis varius quam quisque.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
});

export default mentors;