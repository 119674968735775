import React, {forwardRef, useRef, useState} from 'react'
import {getRest} from "../modules/getRest";
import {useCookies} from "react-cookie";

import VIDOELISTEDIT from './videoListEdit.js'
import background1 from "../assets/images/swiper-1-slide-1.jpg";
import VIDEOLIST from "./videoList";
import {useParams} from "react-router-dom";

const slideStyle1={ backgroundImage: `url(${background1})`};

function Addvideo(props, ref){

    const [cookies, setCookie] = useCookies(['session', 'cats', 'video'])
    const [cats, setCats] = useState({});
    const [videos, setVideos] = useState({});
    const [cat, setSelectedCat] = useState(null);

    return(
        <>
            <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                </div>
                <div className="breadcrumbs-panel">
                </div>
            </section>
            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2>Administration Playlists</h2>
                </div>
            </section>

            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <p className="big wow fadeInUp">Kategorien wählen</p>
                    <p className="big wow fadeInUp">&nbsp;</p>
                    <VIDOELISTEDIT session={cookies.session} language={props.language}/>
                </div>
            </section>
        </>
    )
}

export default forwardRef(Addvideo)