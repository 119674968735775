import React, {forwardRef, useImperativeHandle, useState} from "react";
import { useCookies } from "react-cookie";
import Select from "react-select";
import Form from 'react-bootstrap/Form'
import Lang_register from '../modules/lang_register.js'
import RegObjectValidation from '../modules/regObjectValidation.js'
import {formaudit} from '../modules/formcheck.js'
import background1 from "../../src/assets/images/swiper-1-slide-1.jpg";
import $ from "jquery";
import signObjectValidation from "../modules/signObjectValidation";

const slideStyle1={ backgroundImage: `url(${background1})`};

function Register(props, ref){

    const [cookies, setCookie, removeCookie] = useCookies(["route", "language"]);

    const [language, setLanguage] = useState(props.language);
    const [e_message, setErrorMessage] = useState('');
    const langreg = Lang_register;
    const regObject = RegObjectValidation;
    const audit = formaudit;

    const selOptions = [
        { value: 'null', label: 'Please select' },
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'Français' },
        { value: 'ge', label: 'Deutsch' },
        { value: 'it', label: 'Italiano' }
    ];

    const [selValue, setSelValue ] = useState(selOptions[0]['value']);
    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    const handleCheckRegForm = () => {
        let formcheck = formaudit(regObject)
        console.log('formcheck', formcheck)
        if(formcheck.success){
            /** Below:miss leading function call, it just uses the same label as error message for displaying success  */
            setErrorMessage(formcheck.message[language]);
            webservicePOST(formcheck.post)

        } else {
            setErrorMessage(formcheck.message[language]);
        }
    }

    function webservicePOST(param) {
        console.log('im called');
        $.ajax({
            url: 'https://www.eusi.ch/webservice/postregisteruser',
            data: JSON.stringify(param),
            type: 'POST',
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).done(function(data){
            dataloaded(data);
        }).fail(function(data){
            console.log('data on fail: ', data)
            setErrorMessage(data['responseJSON'].result.message);
        });
    }

    const dataloaded = function(data) {
        console.log(data);
        if (data.hasOwnProperty('success')) {
            window.location.href = '/signin';
        } else {
            setErrorMessage(regObject.server_error_message[language]);
        }
    }


    /*
        async function postUser(obj){
            let url = 'https://www.eusi.ch/php/PostUser.php';
            let options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            };
            let response = await fetch(url, options);
            let responseOK = response && response.ok;
            if (response) {
                let data = await response.json();
                console.log(data);
                return data;
            }
        }*/

    const handleSelectLanguageChange = (e) =>{
        console.log(e.value)
        setSelValue(e.value);
        regObject.form.reg_languagepref.value = e.value
        console.log('select language', e.value);
    }
    const handleSelectSportChange = (e) =>{
        console.log(e.value)
        //setSelValue(e.value);
        regObject.form.reg_sport.value = e.value
        console.log('select sport', e.value);
    }

    const handleChange = (event) => {
        console.log('e target value: ', event.target.value)
        regObject.form[event.target.id].value = event.target.value;
    }

    function handleBirthdayChange(event) {
        regObject.form.reg_birthday.value = event.target.value;
    }

    const dateFormStyle = {
        'font':'inherit',
        'height':'43px '
    }

    const smallText = {
        fontSize:'10px'
    }

    return(
        <>
            { /*    <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">&nbsp;</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>{langreg.subheadline[language]}</span></li>
                        </ul>
                    </div>
                </div>
            </section> */}

            <section className="section section-lg bg-default">
                <div className="container">
                    <div className="row row-30 col-md-12 d-flex justify-content-center" >
                        <div className=" col-md-9 col-xl-9">
                            <h4>{langreg.regheadline[language]}</h4>
                            <p className="title-2 no-text-capitalize">{langreg.regingress[language]} </p>
                            {/*<ul className="list-inline list-social-2">
                                <li><a className="icon mdi mdi-facebook" href="https://www.facebook.com">&nbsp;</a></li>
                                <li><a className="icon mdi mdi-google" href="https://www.google.com">&nbsp;</a></li>
                                <li><a className="icon mdi mdi-twitter" href="https://www.twitter.com">&nbsp;</a></li>
                            </ul> */}

                            {/* <form className="rd-form rd-mailform rd-form-centered">*/}
                            <form className="rd-form rd-mailform rd-form-centered ">
                                <div className="form-wrap" >
                                    <input className="form-input" id="reg_name" type="text" name="name" placeholder={langreg.form.name.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_fname" type="text" name="email" placeholder={langreg.form.fname.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_email" type="text" name="email" placeholder={langreg.form.username.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_phone" type="text" name="email" placeholder={langreg.form.phone.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <label style={smallText}>{langreg.form.password.label[language]}</label>
                                    <input className="form-input" id="reg_pass" type="password" name="email" placeholder={langreg.form.password.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap" >
                                    <label>{langreg.form.sport.placeholder[language]}</label>
                                    <Select
                                        className="form-input"
                                        name={'Sport'}
                                        options={langreg.form.sport[language]}
                                        onChange={handleSelectSportChange}
                                    />
                                </div>
                                <div className="form-wrap" >
                                    <label>{langreg.form.langprefrence.label[language]}</label>
                                    <Select
                                        className="form-input"
                                        name={'some name'}
                                        options={selOptions}
                                        onChange={handleSelectLanguageChange}
                                    />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_street" type="text" name="email" placeholder={langreg.form.street.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_city" type="text" name="email" placeholder={langreg.form.city.placeholder[language]} onChange={handleChange} />
                                </div>
                                <div className="select-wrap">
                                    <label>{langreg.form.birthday.label[language]}</label>
                                    <Form.Control type={'date'} placeholder={'Enter birthday'} className="form-input"  defaultValue={'Please select birthday'} onChange={handleBirthdayChange} />
                                </div>
                                <div>
                                    <p className="error">{e_message}</p>
                                </div>
                                <div className="form-button mt-4">
                                    <button className="button button-lg button-gradient" type="button" onClick={handleCheckRegForm}>{langreg.form.button[language]}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default forwardRef(Register);