import React from 'react'
import background1 from "../assets/images/swiper-1-slide-3.jpg";
const slideStyle1={ backgroundImage: `url(${background1})`};

class profile extends React.Component{

    render () {
        return (
            <>
                <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                    <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                    <div className="breadcrumbs-main">
                        <div className="container">
                            <h2 className="breadcrumbs-custom-title scroll-title">Dein Profil</h2>
                        </div>
                    </div>
                    <div className="breadcrumbs-panel">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="section section-lg bg-default">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-11">
                                <div className="card-group-custom card-group-minimal" id="accordion1" role="tablist"
                                     aria-multiselectable="false">
                                    {/*Bootstrap card */}
                                    <article className="card card-custom card-minimal">
                                        <div className="card-header" role="tab">
                                            <div className="card-title">
                                                <a id="accordion1-card-head-ynvntfal"
                                                   data-toggle="collapse"
                                                   data-parent="#accordion1"
                                                   href="#accordion1-card-body-cwtjxlmd"
                                                   aria-controls="accordion1-card-body-cwtjxlmd"
                                                   aria-expanded="true" role="button">Your profile
                                                <div className="card-arrow"></div></a></div>
                                        </div>
                                        <div className="collapse show" id="accordion1-card-body-cwtjxlmd"
                                             aria-labelledby="accordion1-card-head-ynvntfal" data-parent="#accordion1"
                                             role="tabpanel">
                                            <div className="card-body">
                                                <p className="big">Details</p>
                                            </div>
                                        </div>
                                    </article>
                                    {/*Bootstrap card */}
                                    <article className="card card-custom card-minimal">
                                        <div className="card-header" role="tab">
                                            <div className="card-title"><a className="collapsed"
                                                                           id="accordion1-card-head-fcsaumvg"
                                                                           data-toggle="collapse"
                                                                           data-parent="#accordion1"
                                                                           href="#accordion1-card-body-pxvgjxfj"
                                                                           aria-controls="accordion1-card-body-pxvgjxfj"
                                                                           aria-expanded="false" role="button">Preferences
                                                <div className="card-arrow"></div></a></div>
                                        </div>
                                        <div className="collapse" id="accordion1-card-body-pxvgjxfj"
                                             aria-labelledby="accordion1-card-head-fcsaumvg" data-parent="#accordion1"
                                             role="tabpanel">
                                            <div className="card-body">
                                                <p className="big">Details</p>
                                            </div>
                                        </div>
                                    </article>
                                    {/*Bootstrap card */}
                                    <article className="card card-custom card-minimal">
                                        <div className="card-header" role="tab">
                                            <div className="card-title"><a className="collapsed"
                                                                           id="accordion1-card-head-jgyoxrrc"
                                                                           data-toggle="collapse"
                                                                           data-parent="#accordion1"
                                                                           href="#accordion1-card-body-qvbdnfvc"
                                                                           aria-controls="accordion1-card-body-qvbdnfvc"
                                                                           aria-expanded="false" role="button">Suggested playlists
                                                <div className="card-arrow"></div></a></div>
                                        </div>
                                        <div className="collapse" id="accordion1-card-body-qvbdnfvc"
                                             aria-labelledby="accordion1-card-head-jgyoxrrc" data-parent="#accordion1"
                                             role="tabpanel">
                                            <div className="card-body">
                                                <p className="big">Even more details - in progress.</p>
                                            </div>
                                        </div>
                                    </article>
                                    {/*Bootstrap card */}
                                    <article className="card card-custom card-minimal">
                                        <div className="card-header" role="tab">
                                            <div className="card-title"><a className="collapsed"
                                                                           id="accordion1-card-head-fislguqs"
                                                                           data-toggle="collapse"
                                                                           data-parent="#accordion1"
                                                                           href="#accordion1-card-body-lofswcfy"
                                                                           aria-controls="accordion1-card-body-lofswcfy"
                                                                           aria-expanded="false" role="button">Stats
                                                <div className="card-arrow"></div></a></div>
                                        </div>
                                        <div className="collapse" id="accordion1-card-body-lofswcfy"
                                             aria-labelledby="accordion1-card-head-fislguqs" data-parent="#accordion1"
                                             role="tabpanel">
                                            <div className="card-body">
                                                <p className="big">Details</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default profile;