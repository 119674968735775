module.exports = global.lang_home = {
    slider: {
        en: {
            h1: "Resources for Exceptional Young Swiss Athletes",
            h2: "Shape your future sports career",
            h3: "",
            h4: "",
            h5: ""
        },
        fr: {
            h1: "Ressources pour les jeunes athlètes suisses exceptionnels",
            h2: "Créez votre future carrière sportive",
            h3: "",
            h4: "",
            h5: "",
        },
        ge: {
            h1: "Ressourcen für junge Schweizer Ausnahmesportler",
            h2: "Gestalten Sie Ihre zukünftige Sportkarriere",
            h3: "",
            h4: "",
            h5: "",
        },
        rr: {
            h1: "Ressourcen für junge Schweizer Ausnahmesportler",
            h2: "Gestalten Sie Ihre zukünftige Sportkarriere",
            h3: "",
            h4: "",
            h5: "",
        },
        register: {
            en: "Register",
            fr: "S'inscrire",
            ge: "Registrieren"
        }


    },
    videotext:{
        ge:'Die E-Learning Plattform für junge Sporttalente',
        en:'The E-Learning Platform for Young Athletes',
        fr:'La site web d`apprentissage en ligne pour les jeunes talents sportifs',
        it:'La piattaforma di e-learning per i giovani talenti dello sport´',
    },
    video:{
        ge:'https://www.eusi.ch/images/Mission Statement DE_V2.mp4',
        en:'https://www.eusi.ch/images/Mission Statement EN_V2.mp4',
        fr:'https://www.eusi.ch/images/Mission Statement EN_V2.mp4',
        it:'https://www.eusi.ch/images/Mission Statement IT_V3.mp4',
    },
    homeurl:{
        ge:'www.eusi-talent.ch',
        en:'www.eusi-talent.ch',
        fr:'www.nos-talent.ch',
        it:'www.nostri-talenti.ch',
    },
    parralax:{
        title:{
            en:'Be the Best You can be!',
            ge:'Jung, Dynamisch und Ambitioniert?',
            fr:'Je crois en Moi!',
            it:'Siate il meglio che potete!'
        },
        text:{
            en:'Eusi Talent helps you to achieve your goals in Sports',
            ge:'Eusi Talent hilft Dir Deine sportlichen Ziele zu erreichen',
            fr:'Nos Talents t\'aide à atteindre tes objectifs sportifs',
            it:'Nostri Talenti vi aiuta a raggiungere i vostri obiettivi sportivi'
        },
        button:{
            en:'Sign up now',
            ge:'Jetzt Anmelden',
            fr:'S`inscrire maintenant',
            it:'Iscriviti ora'
        }
    },
    register:{
        ge: {
            title: "Registriere Dich noch heute",
            sub: "Profitiere vom grossen Fachwissen unserer Mentoren und Mentorinnen.",
            button: "Kostenlos registrieren",
            alttitle:"Erzähl mir mehr",
            altsub:"Erfahre mehr über Eusi Talent, unsere Idee und unser Team.",
            altbutton:"MEHR INFOS"
        },
        en: {
            title: "Sign up today",
            sub: "Benefit from the vast expertise of our mentors.",
            button: "Free registration",
            alttitle:"Tell me more",
            altsub:"Learn more about Eusi Talent, our Idea and our Team.",
            altbutton: "MORE INFO",
        },
        fr: {
            title: "Enregistre-toi aujourd'hui",
            sub: "Profite de la vaste expertise de nos Mentors.",
            button: "Inscription gratuite",
            alttitle:"Dis m'en plus",
            altsub:"En savoir plus sur Nos Talents, notre Idée et notre Équipe.",
            altbutton: "PLUS D'INFOS",
        },
        it: {
            title: "Registrati oggi.",
            sub: "Beneficiare della vasta esperienza dei nostri mentori.",
            button: "Registrazione gratuita",
            alttitle:"Dimmi di più",
            altsub:"Per saperne di più su Nostri Talenti, la nostra Idea e il nostro Team.",
            altbutton: "PIÙ INFORMAZIONI",
        },
    },
    midsection:{
        ge: {
            title: "UNSERE PLATTFORM",
            sub: "Eusi-Talent gibt Dir Zugang zu wichtigem  Know-How im Video Format.",
            text: "Mit unserem Know-How machst Du wichtige Fortschritte als Breiten- oder Leistungsportler/in.",
            first:"Anzahl junge Sportler mit einer potentiellen Profikarriere in der Schweiz.",
            second:"Dein Verbesserungpotential durch Registration bei Eusi Talent.",
            third:"Anzahl Mitglieder bei Eusi Talent."
        },
        fr: {
            title: "NOTRE PLATEFORME",
            sub: "Nos Talents te donne accès à un savoir-faire important au format vidéo.",
            text: "Grâce à notre savoir-faire, tu feras d'importants progrès en tant que sportif/sportive de masse ou de compétition.",
            first:"Nombre de jeunes sportifs ayant une carrière professionnelle potentielle en Suisse.",
            second:"Ton potentiel d'amélioration grâce à l'enregistrement chez Nos Talents.",
            third:"Nombre de membres chez Nos Talents." +
                ""
        },
        it: {
            title: "LA NOSTRA PIATTAFORMA",
            sub: "Nostri Talenti vi dà accesso a un importante know-how in formato video.",
            text: "Con il nostro know-how, farete importanti progressi come atleti amatoriali o agonisti.",
            first:"Numero di giovani atleti con una potenziale carriera professionale in Svizzera.",
            second:"Il vostro potenziale di miglioramento attraverso l'iscrizione a Nostri Talenti.",
            third:"Numero di membri di Nostri Talenti."
        },
        en: {
            title: "OUR PLATFORM",
            sub: "Eusi-Talent gives you access to important know-how in video format.",
            text: "With our know-how you will make important progress as an amateur or competitive athlete.",
            first:"Number of young athletes with a potential professional career in Switzerland.",
            second:"Your improvement potential through registration at Eusi Talent.",
            third:"Number of members at Eusi Talent."
        },
    },
    videosection: {
        headline: {
            en: "Our Misson",
            fr: "Notre mission",
            ge: "Unsere Mission",
            it:  "La nostra missione",
        },
        ingress: {
            en: "Our event unites athletic professionals and enthusiasts to pursue a great goal.",
            fr: "Notre événement réunit des professionnels et des passionnés du sport pour poursuivre un grand objectif.",
            ge: "Unsere Veranstaltung bringt Sportprofis und Sportbegeisterte zusammen, um ein großes Ziel zu verfolgen.",
            rr:  "..",
        },
        story: {
            en: "We create and promote initiatives that enable people to become and " +
                "remain physically active regardless and challenge inequality of access to physical" +
                "activity.",
            fr: "Nous créons et promouvons des initiatives qui permettent aux gens de devenir et de rester " +
                "physiquement actifs sans tenir compte des inégalités d'accès à l'activité physique. l'activité physique.",
            ge: "Wir schaffen und fördern Initiativen, die es den Menschen ermöglichen, unabhängig von ihrer Herkunft körperlich aktiv " +
                "zu werden und zu bleiben, und bekämpfen die Ungleichheit beim Zugang zu körperlicher Aktivität.",
            rr:  "..",
        }
    },
    videocategories:{
        headline:{
            en:'Video content',
            ge:'Videoinhalte',
            fr:'Contenu vidéo',
            it:'Contenuti video'
        },
        en:['Football', 'Winter Sports', 'Money & Taxes', 'Health & Nutrition', 'Sport & Education', 'Jobs in Sport', 'Social Media & Media', 'Sports Psychology'],
        ge:['Fussball', 'Wintersport', 'Geld & Steuern', 'Gesundheit & Ernährung', 'Sport & Ausbildung', 'Sport als Beruf', 'Social Media & Medien', 'Sport & Psyche'],
        fr:['Football', 'Sports d’Hiver', 'Argent et Impôts', 'Santé et Nutrition', 'Sport et Formation', 'Sport et Profession', 'Social et Médias', 'Psychologie du Sport'],
        it:['Calcio', 'Sport Invernali', 'Soldi e Tasse', 'Salute e Nutrizione', 'Sport e Formazione', 'Lo Sport come Professione', 'Media Sociali e Media', 'Sport e Psiche']
    }

}