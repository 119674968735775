import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import lang_emailverified from '../modules/lang_emailverified.js'
import lang_config from '../modules/lang_config.js'
import lang_configsignin from '../modules/lang_configsignin.js'
import signObjectValidation from '../modules/signObjectValidation'
//import {postRest} from '../modules/postRest.js'
import $ from 'jquery'
//import somepost from '../modules/postRest.js'
import {formaudit} from '../modules/formcheck.js'
import background1 from "../../src/assets/images/swiper-1-slide-1.jpg";
//import axios from "axios";
const slideStyle1={ backgroundImage: `url(${background1})`};

const Emailverified = (props, ref) => {

    const [cookies, setCookie, removeCookie] = useCookies(["route", "session", "user"]);
    const [language, setLanguage] = useState((props.language) ? props.language : lang_config.languageSelected);
    const [e_message, setErrorMessage] = useState({});
    const signObject = signObjectValidation;
    const navigate = useNavigate();
    //const audit = formaudit;

    console.log('language' , language)

    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    const handleChange = (event) => {
        signObject.form[event.target.id].value = event.target.value;
    }

    function webservicePOST(func_to_call, param) {
        $.ajax({
            url: 'https://www.eusi.ch/webservice/postsignin',
            data: JSON.stringify(param),
            type: 'POST',
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).done(function(data){
            dataloaded(data);
        }).fail(function(data){
            console.log('data on fail: ', data)
        });
    }

    const dataloaded = function(data){
        console.log(data);
        if(!data.hasOwnProperty('success')){
            setErrorMessage(signObjectValidation.server_error_message);
            setCookie("session", '', {path:'/'});
        } else {
            setCookie("user", data.result, { path: '/' });
            setCookie("session", data.result.id, { path: '/' });
            console.log('cookie session ', cookies.session);
            window.location.href = '/';
        }

    }

    const handleCheckRegForm = () => {
        let formcheck = formaudit(signObject);
        if(formcheck.success){
            console.log(formcheck.message);
            /** Below:miss leading function call, it just uses the same label as error message for displaying success  */
            setErrorMessage('Attempting to send to server');
            webservicePOST('dataloaded', formcheck.post);
            //{
            //postRest(formcheck.post, 'https://www.eusi.ch/webservice/postsignin').then(r => {
            //    console.log(r);
            //    if(r.hasOwnProperty('success')){
            //        if(r.result['loggedin'] === false){
            //            console.log("does this hit");
            //             setErrorMessage(signObjectValidation.server_error_message);
            //            setCookie("session", '', {path:'/'});
            //        } else {
            //            setCookie("user", r.result, { path: '/' });
            //            setCookie("session", r.result.id, { path: '/' });
            //            console.log('cookie session ', cookies.session);
            //            window.location.href = '/';
            //        }
            //    }
            //});

        } else {
            setErrorMessage(formcheck.message);
        }
    }

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                        <h2 className="breadcrumbs-custom-title scroll-title">{lang_emailverified.headline[language]}</h2>
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><span>{lang_emailverified.subheadline[language]}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-lg bg-default">
                <div className="container">
                    <div className="row row-30 col-md-12 d-flex justify-content-center" >
                        <div className=" col-md-8 col-xl-8">
                            <h4>{lang_emailverified.ingress[language]}:
                            </h4>
                        </div>
                        <div className=" col-md-8 col-xl-8">
                            <form className="rd-form rd-mailform rd-form-centered">
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_email" type="text" name="email" placeholder={lang_configsignin.signin.email[language]} onChange={handleChange} />
                                </div>
                                <div className="form-wrap">
                                    <input className="form-input" id="reg_pass" type="password" name="email" placeholder={lang_configsignin.signin.password[language]} onChange={handleChange} />
                                </div>
                                <div>
                                    <p className="error">{e_message[language]}</p>
                                </div>
                                <div className="form-button">
                                    <button className="button w-100" type="button" onClick={handleCheckRegForm}>{lang_configsignin.signin.button[language]}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default forwardRef(Emailverified)