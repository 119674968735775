module.exports = global.lang_about = {

    title:{
        ge:'EUSI IDEE',
        fr:'NOTRE IDÉE',
        it:'NOSTRA IDEA',
        en:'OUR IDEA',
    },
    mission:{
        ge:'Eusi Talent ist eine auf die Bedürfnisse von jungen Sportlern und ' +
            'Sportlerinnen zugeschnittene E-Learning Plattform. In zahlreichen Videos ' +
            'wird Know-How und Wissen vermittelt, wie z.B. über Gesundheit, Steuern, ' +
            'Beruf & Sport, oder Trainingsmethoden. Eusi Talent ist für die Benutzer ' +
            'kostenlos und wird durch Spenden und Sponsorengelder finanziert.',
        en:'Eusi Talent is an e-learning platform tailored to the needs of young athletes. ' +
            'In numerous videos know-how and knowledge is imparted, such as about health, ' +
            'taxes, profession & sports, or training methods. Eusi Talent is free of charge ' +
            'for the users and is financed by donations and sponsorships.',
        fr:'Nos Talents est une plateforme d\'apprentissage en ligne conçue pour ' +
            'répondre aux besoins des jeunes sportifs et sportives. De nombreuses vidéos ' +
            'permettent de transmettre un savoir-faire et des connaissances, par exemple ' +
            'sur la santé, la fiscalité, la profession et le sport ou les méthodes d\'entraînement. ' +
            'Eusi Talent est gratuit pour les utilisateurs et est financé par des dons et des sponsors.',
        it:'Nostri Talenti è una piattaforma di e-learning pensata per le esigenze dei giovani atleti. ' +
            'In numerosi video vengono trasmessi know-how e conoscenze, ad esempio ' +
            'su salute, tasse, professione e sport o metodi di allenamento. ' +
            'Eusi Talent è gratuito per gli utenti ed è finanziato da donazioni e sponsorizzazioni.',
    },
    videolink:{
        ge:'https://youtu.be/PharezYz7ps',
        en:'https://youtu.be/g2ssPP5V53E',
        fr:'https://youtu.be/g2ssPP5V53E',
        it:'https://youtu.be/vktlVavNjN4',
    },
    impressum:{
        ge:'IMPRESSUM',
        en:'IMPRINT',
        fr:'IMPRESSION',
        it:'IMPRONTA',
    }
}