import React, {useState, forwardRef, useImperativeHandle} from 'react';
import lang_impress from "../modules/lang_impressum.js";
import background1 from "../../src/assets/images/swiper-1-slide-3.jpg";
import {useCookies} from "react-cookie";

const slideStyle1={ backgroundImage: `url(${background1})`};
const image_dir = "https://www.eusi.ch/images/";


const impressum = forwardRef((props, ref) => {
    const [cookies, setCookie, removeCookie] = useCookies(["language"]);
    const [language, setLanguage] = useState(props.language);
    const impress = lang_impress;


    useImperativeHandle(ref, () => ({
        setRouteLanguage(lang){
            setLanguage(lang);
        }
    }));

    return (
        <>
            <section className="breadcrumbs-custom bg-image context-dark scroll-handler" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default text-center">
                <div className="container">
                    <h2>{impress.titles[language].h1}</h2>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h1}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <p>{impress.text[language].h1}</p>
                    </div>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h2}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <p>{impress.text[language].h2}</p>
                    </div>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h3}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <p>{impress.text[language].h3}</p>
                    </div>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h4}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <p>{impress.text[language].h4}</p>
                    </div>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h5}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <p>{impress.text[language].h5}</p>
                    </div>
                    <article className="quote-minimal">
                        <div className="heading-4 quote-minimal-text">
                            <p className="q">{impress.titles[language].h6}</p>
                        </div>
                    </article>
                    <div className="big text-left">
                        <a href="https://www.eusi.ch/images/terms.de.pdf">PDF</a>

                    </div>
                </div>
            </section>
            <section>
                <div>

                </div>
            </section>
        </>
    );
});

export default impressum;