import React, {Component } from 'react';
import videoObjectValidation from '../modules/videoObjectValidation'
import subcats from '../modules/subcats'
import {formaudit} from '../modules/formcheck.js'
import {deleteRest} from '../modules/deleteRest'
import {postRest} from '../modules/postRest.js'
import {patchRest} from '../modules/patchRest.js'
import {getRest} from "../modules/getRest";
import {Button, Modal} from "react-bootstrap";

//console.log(subcats.football.ge[0])

class videoListEdit extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            inputmode: false,
            editmode: false,
            edititem: '',
            editid: '',
            e_message: '',
            language: '',
            videoList: '',
            videoListRender: '',
            category:'0',
            session:'',
            catObject:{},
            catMenu:[]
        }

        this.catMenu = []
        this.cats = {}
        this.videoObject = videoObjectValidation;
    }

    handleCatChange = event => {
        this.renderVideoList(event.target.id, this.state.videoList)
    }

    changeToEditmode = event => {
        if(document.getElementById('movieform')) {
            document.getElementById('movieform').reset();
        }

        let idx = null
        let vl = this.state.videoList;
        for(let i = 0; i < vl.length; i++) {
            if(vl[i]['v_id'] === event.target.id){
                idx = i;
                break;
            }
        }
        this.addValuesToVideoObject(this.state.videoList[idx])
        this.setState({
            inputmode: false,
            editmode: true,
            edititem: this.state.videoList[idx],
            editid: event.target.id
        });
    }

    addValuesToVideoObject = (obj) => {
        this.videoObject.form.v_id.value = obj['v_id'];
        this.videoObject.form.v_parent.value = this.state.category;
        this.videoObject.form.v_youtube.value = obj['v_youtube'];
        this.videoObject.form.v_title_en.value = obj['v_title_en'];
        this.videoObject.form.v_title_fr.value = obj['v_title_fr'];
        this.videoObject.form.v_title_ge.value = obj['v_title_ge'];
        this.videoObject.form.v_title_it.value = obj['v_title_it'];
        this.videoObject.form.v_desc_en.value = obj['v_desc_en'];
        this.videoObject.form.v_desc_fr.value = obj['v_desc_fr'];
        this.videoObject.form.v_desc_ge.value = obj['v_desc_ge'];
        this.videoObject.form.v_desc_it.value = obj['v_desc_it'];
        this.videoObject.form.v_mentor.value = obj['v_mentor'];

    }

    renderVideoList = (category, videoList) => {
        let rows = [];
        videoList.forEach((item) => {
            if(item['v_parent'] === category){
                rows.push(<tr key={'tr_' + item['v_id']} className="flex-column">
                        <td align="left" key={'td0_' + item['v_id']} className="flex-column">{item['v_id']}</td>
                        <td align="left" key={'td1_' + item['v_id']} className="flex-column">{item['v_title_en']}</td>
                        <td align="left" key={'td2_' + item['v_id']} className="flex-column">{item['v_desc_en']}</td>
                        <td align="left" key={'td3_' + item['v_id']} className="flex-column">{item['v_youtube']}</td>
                        <td align="left" key={'td4_' + item['v_id']} className="flex-column">{item['v_parent']}</td>
                        <td align="left" key={'td5_' + item['v_id']} className="flex-column"><Button id={item['v_id']} onClick={this.changeToEditmode} className="btn btn-danger btn-sm">edit</Button></td>
                        <td align="left" key={'td6_' + item['v_id']} className="flex-column"><Button id={item['v_id']} onClick={this.deleteVideo} className="btn btn-danger btn-sm">delete</Button></td>
                    </tr>);
            }
        });
        this.setState({
            inputmode: false,
            editmode:false,
            category:category,
            videoList: videoList,
            videoListRender: rows
        });
    }

    setErrMessage = (val) => {
        this.setState({
            e_message:val
        })
    }

    handleChange = event =>{
        this.videoObject.form[event.target.id].value = event.target.value;
    }

    handleCheckForm = () => {
        let formcheck = formaudit(this.videoObject)
        if(formcheck.success){
            if(this.state.inputmode === true) {
                postRest(formcheck.post, 'https://www.eusi.ch/webservice/postvideo').then(r => {
                    if(r.success === 200) {
                        this.renderVideoList(this.state.category, r.result.video_list)
                    };
                })
            } else {
                let data = {
                    session:this.props.session,
                    video:formcheck.post
                }
                patchRest( data,'https://www.eusi.ch/webservice/updatevideo').then(r => {
                    if(r.success === 200) {
                        this.renderVideoList(this.state.category, r.result.video_list)
                    }
                })
            }
        } else {
            this.setErrMessage(formcheck.message[this.props.language])
        }

    }

     changeToInputMode = () => {
        this.videoObject.form.v_id.value = 'tba';
        this.videoObject.form.v_parent.value = this.state.category;
        this.videoObject.form.v_youtube.value = '';
        this.videoObject.form.v_title_en.value = '';
        this.videoObject.form.v_title_fr.value = '';
        this.videoObject.form.v_title_ge.value = '';
         this.videoObject.form.v_title_it.value = '';
        this.videoObject.form.v_desc_en.value = '';
        this.videoObject.form.v_desc_fr.value = '';
        this.videoObject.form.v_desc_ge.value = '';
         this.videoObject.form.v_desc_it.value = '';
        this.videoObject.form.v_mentor.value = '';
        this.setState({
            inputmode:true,
            editmode:false
        })
    }

    getVideoCategories = () => {
        getRest('allvideoscategories/' + this.props.session).then(r => {
            this.renderCategories(r.result.cats, r.result.videos);
        });
    }

    deleteVideo = event => {
        let data = {
            'session': this.props.session,
            'deleteid': event.target.id
        }
        deleteRest(data, 'https://www.eusi.ch/webservice/deletevideo').then(r =>{
            if(r.hasOwnProperty('result')){
                this.renderVideoList(this.state.category, r.result.videos)
            }
        })
    }

    renderCategories(cats, videoList){
        let rows = [];
        cats.forEach((item) => {
            rows.push(<Button className="sm mr-3 mb-3" key={item['vc_id']} id={item['vc_url']} onClick={this.handleCatChange}>{item['vc_url']}</Button>);
        });
        this.setState({
            catObject:cats,
            videoList: videoList,
            catMenu: rows
        });
    }



    render(){
        if(Object.keys(this.state.catObject).length < 1) {
            this.getVideoCategories();
        }

        return(
            <>


                    {this.state.catMenu}
                    <table className="col-12 mb-1" >
                        <tbody>
                            <tr className="flex-column">
                                {(this.state.category !== '0') &&
                                <td>
                                    <Button className="sm mb-5 mt-5" onClick={this.changeToInputMode}>Add new {this.state.category} film</Button>
                                </td>
                                }
                            </tr>
                        </tbody>
                    </table>


                {(this.state.inputmode || this.state.editmode) &&
                <div>
                    <form className="rd-form rd-mailform" id="movieform">
                            <div className="row row-10 gutter-10 align-content-md-center">
                                <div className="form-wrap col-12">
                                    <label>Id:</label>
                                    <input className="form-input" id="v_id" type="text" disabled defaultValue={this.state.editid} placeholder={(this.state.editmode) ? this.state.editid : 'tbd'} />
                                </div>
                                <div className="form-wrap col-12 float-left">
                                    <label >Category: {this.state.category}</label>
                                    <input className="form-input" id="v_parent" type="text" disabled placeholder={this.state.category} />
                                </div>
                                <div className="form-wrap col-6 ">
                                    <label >Youtube:</label>
                                    <input className="form-input" id="v_youtube" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_youtube'] : ''} placeholder={this.videoObject.form.v_youtube.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-6 float-left">
                                    <label >Mentor:</label>
                                    <input className="form-input" id="v_mentor" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_mentor'] : ''} placeholder={this.videoObject.form.v_mentor.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-6 align-content-end">
                                    <label >Title English:</label>
                                    <input className="form-input" id="v_title_en" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_title_en'] : ''} placeholder={this.videoObject.form.v_title_en.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-6 float-right">
                                    <label >Title French:</label>
                                    <input className="form-input" id="v_title_fr" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_title_fr'] : ''} placeholder={this.videoObject.form.v_title_fr.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-6 float-right mb-4">
                                    <label >Titles German:</label>
                                    <input className="form-input" id="v_title_ge" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_title_ge'] : ''} placeholder={this.videoObject.form.v_title_ge.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-6 float-right mb-4">
                                    <label >Titles German:</label>
                                    <input className="form-input" id="v_title_it" type="text" defaultValue={(this.state.editmode) ? this.state.edititem['v_title_it'] : ''} placeholder={this.videoObject.form.v_title_it.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-12">
                                    <label >Description English:</label>
                                    <input className="form-input" id="v_desc_en" type="textarea" defaultValue={(this.state.editmode) ? this.state.edititem['v_desc_en'] : ''} placeholder={this.videoObject.form.v_desc_en.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-12">
                                    <label >Description French:</label>
                                    <input className="form-input" id="v_desc_fr" type="textarea" defaultValue={(this.state.editmode) ? this.state.edititem['v_desc_fr'] : ''} placeholder={this.videoObject.form.v_desc_fr.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-12">
                                    <label >Description German:</label>
                                    <input className="form-input" id="v_desc_ge" type="textarea" defaultValue={(this.state.editmode) ? this.state.edititem['v_desc_ge'] : ''} placeholder={this.videoObject.form.v_desc_ge.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-12">
                                    <label >Description Italian:</label>
                                    <input className="form-input" id="v_desc_it" type="textarea" defaultValue={(this.state.editmode) ? this.state.edititem['v_desc_it'] : ''} placeholder={this.videoObject.form.v_desc_it.placeholder.en} onChange={this.handleChange} />
                                </div>
                                <div className="form-wrap col-12 block-center">
                                    <p className="error">{this.state.e_message}</p>
                                </div>
                                <div className="form-button block-center">
                                    <button className="button button-gray-100 button-sm mb-5" type="button" onClick={this.handleCheckForm}>{this.videoObject.button.name.en}</button>
                                </div>
                            </div>
                    </form>
                </div>}
                {this.state.videoListRender.length > 0 &&
                <table className="col-12 flex-shrink-0">
                    <tbody className="table d-sm-table">
                    {
                    <tr className="flex-column">
                        <td align="left" className="font-weight-bold flex-column">Id</td>
                        <td align="left" className="font-weight-bold flex-column">Title</td>
                        <td align="left" className="font-weight-bold flex-column">Description</td>
                        <td align="left" className="font-weight-bold flex-column">Youtube link</td>
                        <td align="left" className="font-weight-bold flex-column">Category</td>
                        <td align="left" className="font-weight-bold flex-column">Edit</td>
                        <td align="left" className="font-weight-bold flex-column">Delete</td>
                    </tr>}
                    {this.state.videoListRender}
                    </tbody>
                </table> }
                </>

        )
    }
}

export default videoListEdit