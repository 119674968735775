module.exports = global.lang_donators = {
    headline:{
        ge:'Eusi Gönner',
        fr:'Donateurs',
        it:'I nostri donatori',
        en:'Donators'
    },
    button:{
        ge:'GÖNNER WERDEN',
        fr:'DEVENIR DONANTEUR',
        it:'COME SUPORTARE',
        en:'HOW TO SUPPORT'
    },
    story:{
        ge:'Eusi Talent ermöglicht jungen Sportlerinnen und Sportlern den kostenlosen Zugang zu Informationen und Tipps, die ihnen auf dem Weg zum sportlichen Erfolg helfen. Dabei achten wir darauf, dass die Benutzer das so vermittelte Wissen ohne grossen Aufwand und ohne Kauf von Produkten direkt anwenden können. Je mehr Themen und Inhalte Eusi Talent anbieten kann, desto mehr können die jungen Sportler und Sportlerinnen profitieren. Alle bei Eusi Talent beteiligten Personen (Vorstand, Mitglieder, Mentoren) arbeiten ohne Entschädigung. Kosten entstehen vor allem im Aufbau und Unterhalt der Webseite sowie bei der Produktion der Videos.\n\n' +
            'Eusi Talent sind Ihnen dankbar für jede Spende.\n',
        fr:'Nos Talents permet aux jeunes sportifs d`accéder gratuitement à des informations et à des conseils qui les aideront sur le chemin de la réussite sportive. Nous veillons à ce que les utilisateurs puissent appliquer directement les connaissances ainsi transmises sans grand effort et sans acheter de produits. Plus Nos Talents peut offrir de thèmes et de contenus, plus les jeunes sportifs et sportives peuvent en profiter. Toutes les personnes impliquées dans Nos Talents (comité directeur, membres, mentors) travaillent sans rémunération. Les coûts sont surtout liés à la construction et à l\'entretien du site web ainsi qu`à la production des vidéos.',
        it:'Eusi Talent offre ai giovani atleti l`accesso gratuito a informazioni e consigli che li aiutano a raggiungere il successo nello sport. Nel fare ciò, poniamo l\'accento sulla fornitura di conoscenze imparziali e attuabili. Più argomenti e contenuti Eusi Talent può offrire, più i giovani atleti possono trarne beneficio. Tutte le persone coinvolte in Eusi Talent (consiglio direttivo, membri, mentori) lavorano senza compenso. I costi riguardano principalmente la codifica e la manutenzione del sito web e la produzione dei video.\n' +
            'Eusi Talent è grato per qualsiasi donazione.\n',
        en:'Eusi Talent gives young athletes free access to information and tips that help them on their way to success in sports. In doing so, we put an emphasis on providing unbiased and actionable knowledge. The more topics and content Eusi Talent can offer, the more the young athletes can benefit. All persons involved in Eusi Talent (board, members, mentors) work without compensation. Costs arise mainly in the coding and maintenance of the website and in the production of the videos.\n\n' +
            'Eusi Talent are grateful for any donation.\n'
    },
    subheadline:{
        ge:'Liste aller Gönner und Gönnerinnen',
        fr:'Liste de tous les donateurs',
        en:'List of Donors',
        it:'Elenco di tutti i donatori'
    }
}