import React, {forwardRef, useImperativeHandle, useState} from 'react'
import Lang_config from '../modules/lang_config'
import language_config from "../modules/lang_config";
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";


function Footer(props, ref){

    //const navigate = useNavigate();
    const [language, setLanguage] = useState(props.language);

    useImperativeHandle(ref, () => ({
        setChildLanguage(lang){
            setLanguage(lang);
        }
    }));

    const noshadow = {
        textShadow:'0px 0px #ffffff'
    }

    const changingLanguage = (lang) =>{
        handleSetCookie(lang);
    };

    function handleSetCookie(lang) {
        let userObj = (cookies.hasOwnProperty('user')) ? cookies.user : {};
        userObj.language = lang
        setCookie("user", userObj, { path: '/' });
        window.location.href = `/language/${lang}`
    }

    const [cookies, setCookie, removeCookie] = useCookies(["route", "session", "user"]);



    return (
        <>
        <footer className="section footer-classic bg-gray-5">
            <div className="footer-classic-body section-lg">
                <div className="container wow fadeInUp">
                    <div className="row row-30 justify-content-lg-between">
                        <div className="col-md-4">
                            <div className="inset-xl-right-70">
                                <div className="heading-6 footer-classic-title">{language_config.footer.about[language].h1}</div>
                                <p>{language_config.footer.about[language].h2}</p>
                                <div className="footer-classic-list-social">
                                    <ul className="list-social list-inline list-inline-md">
                                        <li><a className="icon mdi mdi-instagram" href="https://www.facebook.com">&nbsp;</a></li>
                                        <li><a className="icon mdi mdi-linkedin" href="https://www.facebook.com">&nbsp;</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4 col-xl-5">
                            <div className="inset-xl-right-70 inset-xl-left-70">
                                <div className="heading-6 footer-classic-title">{language_config.footer.newsletter[language].h1}</div>
                                <form className="rd-form form-style-1 rd-mailform"
                                      data-form-output="form-output-global" data-form-type="subscribe" method="post"
                                      action="/">
                                    <div className="form-wrap">
                                        <input className="form-input" id="subscribe-form-email" type="email" name="email" data-constraints="@Email @Required" />
                                        <label className="form-label" htmlFor="subscribe-form-email">
                                            <span className="icon mdi mdi-email-outline">&nbsp;</span><span>{language_config.footer.newsletter[language].h2}</span>
                                        </label>
                                    </div>
                                    <button className="button button-block button-gradient"
                                            type="submit">{language_config.footer.newsletter[language].h3}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="inset-xl-left-70">
                                <div className="heading-6 footer-classic-title">{language_config.footer.contacts[language]}</div>
                                <ul className="contacts-modern">
                                    <li>Allmeindstrasse 17, 8840 Einsiedeln</li>
                                    <li><Link to="mailto:info@demolink.org">info@eusi-talent.ch</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-classic-panel">
                <div className="container wow fadeInUp">
                    <div
                        className="row row-20 align-items-center justify-content-center justify-content-md-between">
                        <div className="col-sm-auto">
                            <div className="footer-classic-brand">

                                <Link className="brand" to={"/"}>
                                    <img className="brand-logo-dark" src="https://www.eusi.ch/images/EUSI_LOGO_bright_vertical.png" alt="" width="117" height="42"/>
                                    <img className="brand-logo-light" src="https://www.eusi.ch/images/EUSI_LOGO_bright_vertical.png" alt="" width="117" height="42"/>
                                </Link>

                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <ul className="footer-classic-nav list-inline list-inline-md">
                                <li><Link to={"/"} onClick={() => changingLanguage('ge')}>DE</Link></li>
                                <li><Link to={"/"} onClick={() => changingLanguage('en')}>EN</Link></li>
                                <li><Link to={"/"} onClick={() => changingLanguage('fr')}>FR</Link></li>
                                <li><Link to={"/"} onClick={() => changingLanguage('it')}>IT</Link></li>

                            </ul>
                        </div>
                        <div className="col-sm-auto">
                            <ul className="footer-classic-nav list-inline list-inline-md">
                                <li><Link to={"/"}>{language_config.menu[language].mm1}</Link></li>
                                <li><Link to={"/about"}>{language_config.menu[language].mm2}</Link></li>
                                <li><Link to={"/"}>{language_config.menu[language].mm3}</Link></li>
                                <li><Link to={"/register"}>{language_config.menu[language].mm5}</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-auto">
                            <p className="rights"><span>EUSI</span><span>&nbsp;&copy;&nbsp;</span>
                                <span className="copyright-year">&nbsp;</span><span>.&nbsp;</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
};

export default forwardRef(Footer);