module.exports = global.resetpassObjectValidation = {
    form: {
        reg_email2:{
            'value':'',
            'required':true,
            'type':'email',
            'label':'',
            'placeholder': {
                en:'Username - Email',
                fr:'Nom d`utilisateur - E-mail',
                ge:'Benutzername - E-Mail'
            },
            err_message:{
                en:'Please enter a valid e-mail',
                fr:'Veuillez saisir une adresse e-mail valide',
                ge:'Bitte geben Sie eine gültige E-Mail-Adresse ein'
            }
        },
        button:{
            name:{
                en:'Click to reset',
                fr:'Cliquez pour réinitialiser',
                ge:'Zum Zurücksetzen klicken'
            }
        }
    },
    formsuccess_message: {
        en: 'Communicating with server',
        fr: 'Communiquer avec le serveur',
        ge: 'Kommunikation mit dem Server'
    },
    server_error_message: {
        en: 'Unable to find any users with that email',
        fr: 'Impossible de trouver des utilisateurs avec cet e-mail',
        ge: 'Es konnten keine Benutzer mit dieser E-Mail-Adresse gefunden werden'
    },
    server_success_message: {
        en: 'Please follow the link just sent to you by email',
        fr: 'Veuillez suivre le lien qui vient de vous être envoyé par e-mail',
        ge: 'Bitte folgen Sie dem Link, den Sie soeben per E-Mail erhalten haben'
    }
}