import React from "react";
import {Link} from "react-router-dom";
import background1 from "../../src/assets/images/ski_community.jpg";
const slideStyle1={ backgroundImage: `url(${background1})`};

class sponsors extends React.Component {
    constructor(props){
        super(props);
        this.headlines = {
            en:'Sponsors',
            ge:'Sponsoren',
            fr:'Sponsors',
            it:'Sponsors'
        }
        this.headlines1 = {
            en:'Bronze Sponsors',
            ge:'Bronze Sponsoren',
            fr:'Sponsors de bronze',
            it:'Sponsor di bronzo'
        }
    }

    render() {
        return (
<>
            <section className="breadcrumbs-custom bg-image context-dark" style={slideStyle1}>
                <div className="image-overlay" data-canvas-src="/images/bg-breadcrumbs.jpg" style={slideStyle1}>&nbsp;</div>
                <div className="breadcrumbs-main">
                    <div className="container">
                    </div>
                </div>
                <div className="breadcrumbs-panel">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section section-xl bg-default text-left">
        <div className="container">
            <h3 className="title-7  fadeInUp">{this.headlines1[this.props.language]}</h3>
            <div className="row row-xl row-30">
                <div className="col-sm-4 col-lg-3  fadeInDown">
                    <a className="client-classic" href="https://www.victorinox.com" target="_blank">
                        <img src="https://www.eusi.ch/images/sponsor_victorinox.png" alt="" width="270" height="145"/>
                    </a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
                <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                    <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                </div>
            </div>
        </div>
    </section>
            <section className="section section-xl bg-default text-left">
                <div className="container">
                    <h3 className="title-7  fadeInUp">{this.headlines[this.props.language]}</h3>
                    <div className="row row-xl row-30">
                        <div className="col-sm-4 col-lg-3  fadeInDown" data--delay=".05s">
                            <a className="client-classic" href="https://www.brack.ch/" target="_blank">
                                <img src="https://www.eusi.ch/images/sponsor_brackch.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInDown" data--delay=".1s">
                            <a className="client-classic" href="https://www.kletterzentrum.com/" target="_blank">
                                <img src="https://www.eusi.ch/images/eusi_sponsor_gaswerk1.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInDown" data--delay=".15s">
                            <a className="client-classic" href="https://www.s-f-b.ch/" target="_blank">
                                <img src="https://www.eusi.ch/images/sponsor_sfb.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInDown" data--delay=".15s">
                            <a className="client-classic" href="https://www.egmond-consulting.com/" target="_blank">
                                <img src="https://www.eusi.ch/images/sponsor1.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInDown" data--delay=".15s">
                            <a className="client-classic" href="#" target="_blank">
                                <img src="https://www.eusi.ch/images/sponsors_ziltener.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInDown">
                            <a className="client-classic" href="https://beachplus.ch" target="_blank">
                                <img src="https://www.eusi.ch/images/eusi_sponsor_beachplus.png" alt="" width="270" height="145"/>
                            </a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                            <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                        </div>
                        <div className="col-sm-4 col-lg-3  fadeInUp"><a className="client-classic" href="/">
                            <img src="https://www.eusi.ch/images/eusi_sponsor_white.png" alt="" width="270" height="145"/></a>
                        </div>
                    </div>
                </div>
            </section>
</>



        )
    }
}

export default sponsors

