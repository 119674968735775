module.exports = global.lang_emailverified={
    headline:{
        en:'Email verified',
        fr:'Email verifié',
        ge:'Email überprüft'
    },
    subheadline:{
        en:'Thank you for register',
        fr:'Merci de vous inscrire',
        ge:'Thank you for register'
    },
    ingress:{
        en:'Sign in to get access',
        fr:'Connectez-vous pour accéder',
        ge:'Melden Sie sich an, um Zugriff zu erhalten'
    },
    ingress2:{
        en:'Sign in:',
        fr:'Connectez-vous:',
        ge:'Melden Sie sich:'
    },
    button:{
        en:'Sign in to get access',
        fr:'Connectez-vous pour accéder',
        ge:'Melden Sie sich an, um Zugriff zu erhalten'
    }
}